import React, { useState, useEffect } from 'react'
import { Box, Button, Skeleton, Stack, TextField } from '@mui/material'
import {
  TSubscription,
  TSubscriptionDetails,
} from '../../../../../core/types/CheckoutOrder'
import ListItemDetail from '../../../../../components/ListItem/ListItemDetail'
import OrderController from '../../../../../core/controllers/OrderController'

export interface IOrderDetailTrackingProp {
  checkoutOrder?: TSubscription
  details?: TSubscriptionDetails
  loading: boolean
  setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
}

const SubscriptionDetailTracking = ({
  checkoutOrder,
  details,
  loading,
  setSending,
  setSuccess,
  setError,
}: IOrderDetailTrackingProp) => {
  const [data, setData] = useState({ ...details })

  const submit = async () => {
    setSending(true)
    setSuccess(false)
    setError('')
    try {
      var response = await OrderController.updateTracking({
        data: {
          checkoutOrderId: data?.orderData?.id,
          src: data?.orderDetails?.src,
          sck: data?.orderDetails?.sck,
          utm_campaign: data?.orderDetails?.utm_campaign,
          utm_source: data?.orderDetails?.utm_source,
          utm_medium: data?.orderDetails?.utm_medium,
          utm_content: data?.orderDetails?.utm_content,
          utm_term: data?.orderDetails?.utm_term,
        },
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
      }
    } finally {
      setSending(false)
    }
  }

  useEffect(() => {
    if (details) {
      setData({ ...details })
    }
  }, [details, setData])

  if (loading) {
    return (
      <Stack spacing={2} sx={{ p: 2 }}>
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
      </Stack>
    )
  }

  return (
    <Box sx={{ pt: 1 }}>
      <ListItemDetail
        title="src"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.orderDetails?.src ?? ''}
            onChange={(e) =>
              setData({
                ...data,
                orderDetails: { ...data.orderDetails!, src: e.target.value },
              })
            }
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="sck"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.orderDetails?.sck ?? ''}
            onChange={(e) =>
              setData({
                ...data,
                orderDetails: { ...data.orderDetails!, sck: e.target.value },
              })
            }
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_campaign"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.orderDetails?.utm_campaign ?? ''}
            onChange={(e) =>
              setData({
                ...data,
                orderDetails: {
                  ...data.orderDetails!,
                  utm_campaign: e.target.value,
                },
              })
            }
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_source"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.orderDetails?.utm_source ?? ''}
            onChange={(e) =>
              setData({
                ...data,
                orderDetails: {
                  ...data.orderDetails!,
                  utm_source: e.target.value,
                },
              })
            }
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_medium"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.orderDetails?.utm_medium ?? ''}
            onChange={(e) =>
              setData({
                ...data,
                orderDetails: {
                  ...data.orderDetails!,
                  utm_medium: e.target.value,
                },
              })
            }
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_content"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.orderDetails?.utm_content ?? ''}
            onChange={(e) =>
              setData({
                ...data,
                orderDetails: {
                  ...data.orderDetails!,
                  utm_content: e.target.value,
                },
              })
            }
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_term"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.orderDetails?.utm_term ?? ''}
            onChange={(e) =>
              setData({
                ...data,
                orderDetails: {
                  ...data.orderDetails!,
                  utm_term: e.target.value,
                },
              })
            }
          />
        }
        viewDivider={false}
        py={1}
      />
      <Stack sx={{ pt: 3, alignItems: 'end', justifyContent: 'end' }}>
        <Button variant="contained" onClick={submit}>
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}

export default SubscriptionDetailTracking
