export const webhookSendExampla = {
  id: '00000000-0000-0000-0000-000000000000',
  event_name: 'Nome do Webhook',
  event_type: 'Pix',
  payment_method: 'Pix',
  event_type_identifier: 'PixGenerated.WaitingPayment',
  order_id: '91d97938-9c75-4a43-8471-1fa754aa9c03',
  order_code: 'V93ET2DCKV',
  order_status: 'WaitingPayment',
  created_date: '2024-07-19T17:06:59.25843+00:00',
  approved_date: null,
  refunded_date: null,
  customer: {
    id: '2b719ad3-3a3d-44ca-bc7e-4118a7772a0c',
    name: 'Nome do Cliente',
    phone: '99999999999',
    email: 'nome@cliente.com',
    document: '00000000000',
    address: {
      id: '347145a7-f9cb-4be8-b748-10746c2e7a60',
      street: 'Rua',
      number: '0',
      complement: '',
      zip_code: '00000000',
      neighborhood: 'Bairro',
      city: 'Cidade',
      state: 'SP',
      country: 'BR',
      reference: '',
    },
  },
  items: [
    {
      id: '1de472a9-c127-4577-93e1-c8ddb94ee468',
      code: '4VSISAIQ',
      name: 'Produto 1',
      amount: 5.05,
      quantity: 1,
      item_type: 'Principal',
      item_type_sale: 'Recorrência',
      frequency: 'Mensal',
      offer_name: 'Oferta Produto 1',
    },
    {
      id: '342d49dd-ae02-4c1f-bfb0-756f485a8b7d',
      code: '74ZKA8WK',
      name: 'Produto 2',
      amount: 55.0,
      quantity: 1,
      item_type: 'Orderbump',
      item_type_sale: 'Pedido',
      frequency: null,
      offer_name: 'Oferta Produto 2',
    },
    {
      id: 'c01c5257-a3be-4879-8e95-c7973890cd83',
      code: 'DK9MRK3H',
      name: 'Produto 3',
      amount: 5.01,
      quantity: 1,
      item_type: 'Orderbump',
      item_type_sale: 'Pedido',
      frequency: null,
      offer_name: 'Oferta Produto 3',
    },
  ],
  charge: {
    id: '91d97938-9c75-4a43-8471-1fa754aa9c03',
    code: '9a3b422f-52b2-4f25-aede-193c29a7721a',
    status: 'pending',
    amount: '65.06',
    gateway_response: 'null',
  },
  lostSaleData: {
    LostSaleId: null,
    Product: null,
    Producer: null,
    CoProducers: null,
    Affialted: null,
    LeadName: null,
    LeadEmail: null,
    LeadPhone: null,
    LeadZipCode: null,
    CreatedDate: null,
    CheckoutModelId: null,
    CheckoutModelName: '',
    TenantId: '00000000-0000-0000-0000-000000000000',
    TenantName: '',
  },
  trackingParameters: {
    sck: '',
    src: '',
    utm_campaign: '',
    utm_content: '',
    utm_medium: '',
    utm_source: '',
    utm_term: '',
  },
  commission: {
    totalPrice: 65.06,
    gatewayFee: 7.68,
    userCommission: 57.38,
    currency: 'BRL',
  },
  pix_data: {
    pix_qr_code: '',
    pix_qr_url: '',
  },
  boleto_data: {
    boleto_url: '',
    boleto_code_bar: '',
    boleto_code_bar_url: '',
    boleto_pdf: '',
    boleto_qr_code: '',
  },
  seller_name: 'Nome do Vendedor',
  seller_type: 'Produtor',
}
