import { Alert, AlertTitle, Box, Button, Chip, Stack } from '@mui/material'
import { useContext } from 'react'
import {
  TSubscriptionDetails,
  TSubscriptionTransaction,
} from '../../../../../../core/types/CheckoutOrder'
import { AuthContext } from '../../../../../../core/context/AuthContext'
import ListItemDefault from '../../../../../../components/ListItem/ListItemDefault'
import Convertions from '../../../../../../core/functions/convertions'
import { dateTimeString } from '../../../../../../core/functions/dateTime'
import NumberFunctions from '../../../../../../core/functions/NumberFunctions'
import { TProductVoucherValue } from '../../../../../../core/types/Voucher'

export interface IOrderDetailOrderProp {
  subscription?: TSubscriptionDetails
  transaction?: TSubscriptionTransaction
  //isOpened: boolean
  //setSending: (value: boolean) => void
  //setSuccess: (value: boolean) => void
  //setError: (value: string) => void
  //execute: () => void
  //getDetails: (id: string, productPriceId?: string) => Promise<void>
  //setCopied: (value: boolean) => void
}

const SubscriptionOrderDetailOrder = ({
  subscription,
  transaction,
  //isOpened,
  //setSending,
  //setSuccess,
  //setError,
  //execute,
  //getDetails,
  //setCopied,
}: IOrderDetailOrderProp) => {
  //const { user, isUserSysAdmin } = useContext(AuthContext)

  /*const handleDownloadBoleto = async (codeId: string) => {
    const boletoURL = await OrderController.getPaymentLink({ codeId })
    window.open(boletoURL.data, '_blank')
  }

  const handleCopyPixCode = async (codeId: string) => {
    const pixCode = await OrderController.getPaymentLink({ codeId })
    Hosts.copyTextToClipboard(pixCode.data)
    setCopied(true)
  }

  const linkColumn = CheckoutOrderLinkColumn({
    setCopied,
    onDownloadBoleto: handleDownloadBoleto,
    onCopyPix: handleCopyPixCode,
    execute,
  })
  const whatsColumn = CheckoutOrderCustomerWhatsAppColumn({ execute })

  const handleChangeUnlock = async () => {
    if (!subscription?.orderData?.id) {
      setError('Nenhuma venda selecionada')
      return
    }

    setSending(true)
    try {
      const response = await OrderController.unlockSale({
        checkoutOrderId: subscription?.orderData.id,
      })

      if (!response.success) {
        setError(response.error)
        return
      }

      setSuccess(true)
      await getDetails(
        subscription?.orderData.id,
        subscription?.orderData.productPriceId
      )
      execute()
    } finally {
      setSending(false)
    }
  }*/

  return (
    <Box>
      <ListItemDefault
        py={0.6}
        title="ID da venda"
        value={
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>{subscription?.orderData?.codeId}</Box>
            {/*<Stack direction="row" spacing={1}>
              {isOpened &&
                linkColumn.render &&
                details &&
                checkoutOrder &&
                linkColumn.render('codeId', details?.codeId, checkoutOrder)}
              {whatsColumn.render &&
                checkoutOrder &&
                whatsColumn.render(
                  'phone',
                  checkoutOrder?.phone,
                  checkoutOrder
                )}
            </Stack>*/}
          </Stack>
        }
      />
      <ListItemDefault
        title="Status"
        value={
          <Box sx={{ width: '100%' }}>
            <Chip
              label={Convertions.checkoutOrderStatusToString(
                transaction?.status
              )}
              size="small"
              color={
                transaction?.status === 'pending'
                  ? 'default'
                  : transaction?.status === 'failed'
                    ? 'error'
                    : transaction?.status === 'paid'
                      ? 'success'
                      : 'warning'
              }
            />
          </Box>
        }
      />
      {transaction?.lastTransaction.error && (
        <Stack sx={{ direction: 'column', mt: 0.5, px: 2 }}>
          <Stack direction="column">
            <Alert severity="warning" sx={{ width: '100%', mb: 1 }}>
              <AlertTitle>
                {Convertions.ckeckoutOrderDetailsError(
                  transaction?.lastTransaction.error.type
                )}{' '}
                -{' '}
                {dateTimeString(transaction?.lastTransaction.error.createdDate)}
              </AlertTitle>
              {Convertions.ckeckoutOrderDetailsDescriptionError(
                transaction?.lastTransaction.error.type
              )}
            </Alert>
          </Stack>
        </Stack>
      )}

      <ListItemDefault
        title="Valor líquido"
        value={NumberFunctions.formatMoneyDefault(
          subscription?.orderDetails?.value
        )}
      />
      <ListItemDefault title="Produto" value={subscription?.productName} />
      <ListItemDefault title="Oferta" value={subscription?.offerName ?? ''} />
      <ListItemDefault
        title="Método de pagamento"
        value={Convertions.paymentMethodToString(
          subscription?.paymentyMethod.toString()
        )}
      />
      <ListItemDefault
        title="Data de criação"
        value={dateTimeString(transaction?.createdDate)}
      />
      {transaction?.lastTransaction.paymentDate && (
        <ListItemDefault
          title="Data de pagamento"
          value={dateTimeString(transaction?.lastTransaction.paymentDate)}
        />
      )}
      {subscription?.orderDetails?.voucherIdentifier !== undefined &&
        subscription?.orderDetails?.voucherIdentifier !== null &&
        subscription?.orderDetails?.voucherIdentifier !== '' && (
          <ListItemDefault
            title="Cupom de desconto"
            value={`${subscription?.orderDetails?.voucherIdentifier} - ${
              subscription?.orderDetails?.voucherType ===
              TProductVoucherValue.value
                ? NumberFunctions.formatMoneyDefault(
                    subscription?.orderDetails?.voucherValue
                  )
                : NumberFunctions.toPercentage(
                    subscription?.orderDetails?.voucherValue
                  )
            }`}
          />
        )}

      {subscription?.orderDetails?.isSubscriptionPaymentCancel && (
        <ListItemDefault
          title="Cobrança da Recorrência"
          value={<Chip label="Cancelado" size="small" color="error" />}
        />
      )}
      {subscription?.orderDetails?.isBlocked && (
        <ListItemDefault title="" px={0} py={0}>
          <Box width="100%">
            <ListItemDefault
              title={
                <Chip label="Venda Bloqueada" size="small" color="error" />
              }
              value={
                subscription?.orderDetails?.reasonBlocked ??
                'Motivo não identificado'
              }
              viewDivider={false}
              px={0}
              py={0}
            />
            {/*isUserSysAdmin() && (
              <Box sx={{ px: 2, pb: 2 }}>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={handleChangeUnlock}
                  sx={{ textTransform: 'none' }}
                >
                  Desbloquear venda
                </Button>
              </Box>
            )*/}
          </Box>
        </ListItemDefault>
      )}
    </Box>
  )
}

export default SubscriptionOrderDetailOrder
