import {
  Alert,
  Box,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import {
  TSubscription,
  TSubscriptionDetails,
  TSubscriptionTransaction,
} from '../../../../core/types/CheckoutOrder'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RepayIcon from '@mui/icons-material/PaymentsOutlined'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import React, { useContext } from 'react'
import OrderDetailOrder from './Tabs/SubscriptionDetailOrder'
import OrderDetailCustomer from './Tabs/SubscriptionDetailCustomer'
import OrderDetailValues from './Tabs/SubscriptionDetailValues'
import OrderController from '../../../../core/controllers/OrderController'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import OrderDetailTracking from './Tabs/SubscriptionDetailTracking'
import ContentDialog from '../../../../components/Dialogs/ContentDialog'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import ImageIlustrationRepay from '../../../../components/Images/ImageIlustrationRepay'
import CancelIcon from '@mui/icons-material/Cancel'
import OrderFunctions from '../../../../core/functions/OrderFunctions'
import { TabPanel, a11yProps } from '../../../../components/Tabs'
import OrderDetailPayments from './Tabs/SubscriptionDetailPayments'
import { AuthContext } from '../../../../core/context/AuthContext'
import { SubscriptionStatusEnum } from '../../../../core/types/Order'

export interface ICheckoutOrderDetailsProp {
  subscription?: TSubscription
  open: boolean
  loading: boolean
  handleClose: () => void
  setOpen: (value: boolean) => void
  setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
  execute: () => void
  setCopied: (value: boolean) => void
}

const SubscriptionDetails = ({
  subscription,
  open,
  loading,
  handleClose,
  setOpen,
  setSending,
  setSuccess,
  setError,
  execute,
  setCopied,
}: ICheckoutOrderDetailsProp) => {
  const { isUserSysAdmin } = useContext(AuthContext)
  const [value, setValue] = React.useState(0)

  const [openRepay, setOpenRepay] = React.useState(false)
  const [openNotRepay, setOpenNotRepay] = React.useState(false)
  const [openSubscriptionPaymentCancel, setOpenSubscriptionPaymentCancel] =
    React.useState(false)

  const [data, setData] = React.useState<TSubscriptionDetails | undefined>(
    undefined
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [menu, setMenu] = React.useState<JSX.Element[]>([])

  const handleMyClose = () => {
    handleMenuClose()
    handleClose()
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleRepay = () => {
    handleMenuClose()
    if (!data?.orderData) {
      setError('Verifique se existe uma cobrança paga para essa assinatura.')
      return
    }

    if (!data?.orderData?.inAssurance) {
      setOpenNotRepay(true)
    } else {
      setOpenRepay(true)
    }
  }

  const handleSubscriptionPaymentCancel = () => {
    handleMenuClose()
    subscriptionPaymentCancel()
  }

  function findMostRecentDate(
    transactions: TSubscriptionTransaction[] | undefined
  ): TSubscriptionTransaction | null {
    if (!transactions || transactions.length === 0) {
      return null
    }

    return transactions
      .map((tran) => {
        if (typeof tran.createdDate === 'string')
          tran.createdDate = new Date(tran.createdDate)
        return tran
      })
      .reduce((latest, current) =>
        current.createdDate > latest.createdDate ? current : latest
      )
  }

  const repay = async (): Promise<boolean> => {
    setSuccess(false)
    setError('')
    try {
      const recentTransaction = findMostRecentDate(
        data?.transactions
      )?.lastTransaction

      const orderChargeId =
        recentTransaction?.orderChargeId ||
        data?.orderDetails.orderChargeId ||
        ''

      var response = await OrderController.deleteCharge({ orderChargeId })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleClose()
        execute()
      }
    } finally {
      return true
    }
  }

  const subscriptionPaymentCancel = async () => {
    setSuccess(false)
    setError('')
    try {
      const checkoutOrderId =
        data?.orderData?.id || data?.orderDetails?.checkoutOrderId || ''

      var response = await OrderController.subscriptionPaymentCancel({
        checkoutOrderId,
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleClose()
        execute()
      }
    } finally {
      return true
    }
  }

  const getDetails = React.useCallback(async (codeId?: string) => {
    try {
      setSending(true)
      setError('')
      if (data) {
        setData(undefined)
      }

      const response = await OrderController.getSubscriptionDetails({
        codeId: codeId ?? '',
      })
      var responseError = response as IErrorResponse

      if (responseError.code) {
        setError(responseError.error)
      } else {
        const responseData = response as TSubscriptionDetails
        setData(responseData)
      }
    } catch (e) {
      var message = 'Erro não identificado'
      if (typeof e === 'string') {
        message = e.toUpperCase()
      } else if (e instanceof Error) {
        const err = e as Error
        message = err.message
      }
      setError(message)
    } finally {
      setSending(false)
    }
  }, [])

  const handleManualCharge = async () => {
    handleMenuClose()
    setSuccess(false)
    setError('')
    setSending(true)
    try {
      var response = await OrderController.subscriptionManualCharge({
        subscriptionCodeId: subscription?.codeId ?? '',
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleClose()
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  const menuRepay = (
    <MenuItem onClick={handleRepay}>
      <ListItemIcon>
        <RepayIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Reembolsar última cobrança</ListItemText>
    </MenuItem>
  )

  const menuCancel = (
    <MenuItem onClick={handleSubscriptionPaymentCancel}>
      <ListItemIcon>
        <CancelIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Cancelar cobrança de assinatura</ListItemText>
    </MenuItem>
  )

  const menuManualPay = (
    <MenuItem onClick={handleManualCharge}>
      <ListItemIcon>
        <PriceCheckIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Cobrança manual da assinatura</ListItemText>
    </MenuItem>
  )

  React.useEffect(() => {
    if (subscription && subscription.id) {
      setValue(0)
      getDetails(subscription.codeId)
    }
  }, [subscription])

  React.useEffect(() => {
    if (subscription && data) {
      const listMenu: JSX.Element[] = []

      if (!OrderFunctions.isSubscriptionDetailsPaymentCancel(data)) {
        listMenu.push(menuRepay)
        listMenu.push(menuCancel)
      }

      if (
        isUserSysAdmin() ||
        subscription.status === SubscriptionStatusEnum.Delayed
      ) {
        listMenu.push(menuManualPay)
      }

      setMenu([...listMenu])
    }
  }, [subscription, data])

  return (
    <SwipeableDrawerRightDialog
      hideButtons
      open={open}
      onClose={handleMyClose}
      setOpen={setOpen}
      backgroundColor="#fafafa"
      title="Detalhes da Assinatura"
      subtitle="Visualize os detalhes da assinatura realizada"
    >
      <>
        <Stack direction="column" sx={{ width: '100%' }}>
          <Box>
            <Stack
              direction="row"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              alignItems="center"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
                sx={{ flexGrow: 1 }}
              >
                <Tab label="Assinatura" {...a11yProps(0)} />
                <Tab label="Cliente" {...a11yProps(1)} />
                <Tab label="Valores" {...a11yProps(2)} />
                <Tab label="Track" {...a11yProps(3)} />
                <Tab label="Transações" {...a11yProps(4)} />
              </Tabs>
              {menu.length > 0 && (
                <div>
                  <IconButton
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    {menu.map((m) => m)}
                  </Menu>
                </div>
              )}
            </Stack>
            <TabPanel value={value} index={0}>
              <OrderDetailOrder
                checkoutOrder={subscription}
                details={data}
                loading={loading}
                setSending={setSending}
                setSuccess={setSuccess}
                setError={setError}
                execute={execute}
                getDetails={getDetails}
                setCopied={setCopied}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <OrderDetailCustomer
                checkoutOrder={subscription}
                details={data}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <OrderDetailValues
                checkoutOrder={subscription}
                details={data}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <OrderDetailTracking
                checkoutOrder={subscription}
                details={data}
                loading={loading}
                setSending={setSending}
                setSuccess={setSuccess}
                setError={setError}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <OrderDetailPayments
                checkoutOrder={subscription}
                details={data}
                loading={loading}
              />
            </TabPanel>
          </Box>
        </Stack>

        <ContentDialog
          open={openRepay}
          onClose={() => setOpenRepay(false)}
          title="Como funciona o reembolso"
          labelPrositive="Reembolsar"
          onPositive={repay}
        >
          <Container>
            <ImageIlustrationRepay />
            <Typography>
              Após a solicitação do estorno, a solicitação é enviada a operadora
              do cartão do cliente, que é o responsável pela devolução do saldo
              no cartão dele.
            </Typography>
            <Typography>
              Assim, o cliente pode receber esse valor de 3 formas de acordo com
              a operadora/banco emissor:
            </Typography>
            <ul>
              <li>
                <Typography>
                  O banco estorna o valor total da transação na fatura seguinte
                  à solicitação do estorno; liberando o limite do cartão do
                  cliente e cobrando o restante das parcelas normalmente.
                </Typography>
              </li>
              <li>
                <Typography>
                  O banco cobra todas as parcelas e faz um estorno total apenas
                  na última fatura da cobrança
                </Typography>
              </li>
              <li>
                <Typography>
                  O banco faz a cobrança e o estorno da parcela na mesma fatura.
                  Em casos de estorno parcial, o banco pode dividir o valor do
                  estorno pelo restante das transações – nesse caso é cobrada
                  uma parcela original e devolvida uma parcela de estorno na
                  mesma fatura.
                </Typography>
              </li>
            </ul>
          </Container>
        </ContentDialog>

        <ContentDialog
          open={openNotRepay}
          onClose={() => setOpenNotRepay(false)}
          title="Operação inválida"
          hasCancel={false}
        >
          <Typography>
            Não é permitido o reembolso após o período de garantia.
          </Typography>
          <Alert
            variant="standard"
            severity="info"
            sx={{ width: '100%', mt: 3 }}
          >
            Só é permitido o reembolso enquanto o produto estiver no período de
            garantia
          </Alert>
        </ContentDialog>

        <ContentDialog
          open={openSubscriptionPaymentCancel}
          onClose={() => setOpenSubscriptionPaymentCancel(false)}
          title="Cancelar assinatura"
          labelPrositive="Aprovar cancelamento de assinatura"
          onPositive={subscriptionPaymentCancel}
        >
          <Typography>
            {' '}
            Ao cancelar a cobrança de assinatura, a assinatura expirará após a
            data de renovação, e o cliente não será cobrado no futuro.{' '}
          </Typography>
        </ContentDialog>
      </>
    </SwipeableDrawerRightDialog>
  )
}

export default SubscriptionDetails
