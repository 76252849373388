import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { TTenantSettings } from '../../../../core/types/Tenant'
import { maskPercentage } from '../../../../masks/masks'
import React, { useCallback } from 'react'
import { grey, red } from '@mui/material/colors'
import { HtmlTooltip } from '../../../../components/Tooltip/HtmlTooltip'
import InfoIcon from '@mui/icons-material/InfoRounded'
import {
  TIndicatedComissionLevelEnum,
  TIndicatedTenantItem,
} from '../../../../core/types/IndicatedTenant'
import IndicatedFunctions from '../../../../core/functions/IndicatedFunctions'
import IndicatedController from '../../../../core/controllers/IndicatedController'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'
import { dateString } from '../../../../core/functions/dateTime'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import BasicDatePicker from '../../../../components/DateTime/BasicDatePicker'
import Hosts from '../../../../core/functions/hosts'
import { useTenantSettingsByTenantId } from '../../../../core/hooks/useTenantSettingsByTenantId'
import { a11yProps, TabPanel } from '../../../../components/Tabs'
import DataTable, {
  IDataTableColumn,
  TDataTablePagination,
} from '../../../../components/Tables/DataTable'
import { TenantController } from '../../../../core/controllers/TenantController'
import UserSettingGeneral from './Tabs/UserSettingGeneral'
import UserSettingValues from './Tabs/UserSettingValues'

export interface IUserSettingProps {
  open: boolean
  sending: boolean
  tenantSettings: TTenantSettings
  handleClose: () => void
  handleSave: () => void
  setOpen: (value: boolean) => void
  setTenantSettings: (value: TTenantSettings) => void
  setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
  setMessage: (value: string) => void
  executeUserSettings: () => void
}

const UserSetting = ({
  open,
  sending,
  tenantSettings,
  handleClose,
  handleSave,
  setOpen,
  setTenantSettings,
  setSending,
  setSuccess,
  setError,
  setMessage,
  executeUserSettings,
}: IUserSettingProps) => {
  const [errorTenant, setErrorTenant] = React.useState('')
  const [errorImmediateWithdrawal, setErrorImmediateWithdrawal] =
    React.useState('')
  const [errorPlatformFee, setErrorPlatformFee] = React.useState('')
  const [errorAnticipationD15Fee, setErrorAnticipationD15Fee] =
    React.useState('')
  const [errorAnticipationD2Fee, setErrorAnticipationD2Fee] = React.useState('')
  const [errorIndicatedValue, setErrorIndicatedValue] = React.useState('')
  const [errorCurrentIndicatedValue, setErrorCurrentIndicatedValue] =
    React.useState('')

  const [loading, setLoading] = React.useState(false)

  const [tabIndex, setTabIndex] = React.useState(0)

  const [indicateds, setIndicateds] = React.useState<TIndicatedTenantItem[]>([])
  const [currentIndicated, setCurrentIndicated] =
    React.useState<TIndicatedTenantItem | null>(null)

  const recipientUrl = `https://dash.pagar.me/merch_4PxlzxbsOhbD02on/acc_qo8Nm36cLh4G71rx/${tenantSettings.recipientId}/recipient-details`

  const dataPagination: TDataTablePagination<TIndicatedTenantItem> = {
    items: indicateds,
    page: 0,
    rowsPerPage: 0,
    total: 0,
    totalPages: 0,
  }

  const { data: settings, loading: loadingSettings } =
    useTenantSettingsByTenantId({
      id: tenantSettings.tenantId,
      requestRecipient: true,
    })

  const columns: readonly IDataTableColumn<TIndicatedTenantItem>[] = [
    {
      id: 'name',
      label: 'Nome',
      minWidth: 140,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <Typography
              variant="subtitle2"
              fontStyle={!row.isEnableCommissions ? 'italic' : undefined}
              sx={{
                textDecoration: !row.isEnableCommissions
                  ? 'line-through'
                  : undefined,
              }}
            >
              {value}
            </Typography>
            <Typography variant="body2">{row.email}</Typography>
          </Stack>
        )
      },
    },
    {
      id: 'indicatedComissionsPercentage',
      label: 'Configurações',
      minWidth: 140,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <Typography
              variant="subtitle2"
              fontStyle={!row.isEnableCommissions ? 'italic' : undefined}
              sx={{
                textDecoration: !row.isEnableCommissions
                  ? 'line-through'
                  : undefined,
              }}
            >
              {IndicatedFunctions.getIndicatedComissionLevelString(
                row.indicatedComissionLevelEnum
              )}
              {' - '}
              {NumberFunctions.toPercentage(row.indicatedComissionsPercentage)}
            </Typography>
            <Typography variant="caption">
              Válido até {dateString(row.receiveComissionUntilDateOf)}
            </Typography>
          </Stack>
        )
      },
    },
    {
      id: 'action',
      label: 'Ações',
      minWidth: 20,
      render(id, value, row) {
        return (
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            sx={{ mr: 4.5 }}
          >
            <Tooltip
              title={
                row.isEnableCommissions
                  ? 'Desativar comissão'
                  : 'Ativar comissão'
              }
            >
              <Button
                size="small"
                color={row.isEnableCommissions ? 'error' : 'primary'}
                onClick={() => handleEnableOrDisableIndicatedComission(row)}
              >
                {!row.isEnableCommissions ? <CheckIcon /> : <BlockIcon />}
              </Button>
            </Tooltip>
            <Tooltip title="Editar">
              <Button
                size="small"
                color="primary"
                onClick={() => setCurrentIndicated(row)}
                disabled={!row.isEnableCommissions}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        )
      },
    },
  ]

  const getIndicatedList = useCallback(async (id: string) => {
    setLoading(true)
    try {
      let response = await IndicatedController.listIndicatedById({ id })
      const responseError = response as IErrorResponse
      const responseData = response as TIndicatedTenantItem[]

      if (responseError.code) {
        setError(responseError.error)
        setIndicateds([])
      } else {
        setIndicateds(responseData)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)

    if (newValue === 1) {
      getIndicatedList(tenantSettings.tenantId)
    }
  }

  const handleChangeSave = () => {
    var error = false

    if (tenantSettings.tenantId === '') {
      setErrorTenant('Campo obrigatório')
      error = true
    }

    const immediateWithdrawal = maskPercentage(
      tenantSettings.immediateWithdrawal
    )
      .replaceAll('.', '')
      .replaceAll(',', '.')
    tenantSettings.immediateWithdrawal = Number(immediateWithdrawal)
    if (tenantSettings.immediateWithdrawal > 90) {
      setErrorImmediateWithdrawal('O valor precisa ser no máximo 90%')
      error = true
    }

    if (tenantSettings.enableAnticipationD15) {
      const anticipationD15Fee = tenantSettings.anticipationD15Fee ?? 0
      if (anticipationD15Fee <= 0 || anticipationD15Fee > 5) {
        setErrorAnticipationD15Fee(
          'O valor precisa ser maior que zero (0%) e menor que cinco (5%)'
        )
        error = true
      }
    }

    if (tenantSettings.enableAnticipationD2) {
      const anticipationD2Fee = tenantSettings.anticipationD2Fee ?? 0
      if (anticipationD2Fee <= 0 || anticipationD2Fee > 5) {
        setErrorAnticipationD2Fee(
          'O valor precisa ser maior que zero (0%) e menor que cinco (5%)'
        )
        error = true
      }
    }

    const indicatedComissionsRateValue = maskPercentage(
      tenantSettings.indicatedComissionsRateValue ?? '0'
    )
      .replaceAll('.', '')
      .replaceAll(',', '.')
    tenantSettings.indicatedComissionsRateValue = Number(
      indicatedComissionsRateValue
    )
    if (tenantSettings.indicatedComissionsRateValue > 30) {
      if (tenantSettings.disableIndicatedComissions) {
        tenantSettings.indicatedComissionsRateValue = 0
      } else {
        setErrorIndicatedValue('O valor precisa ser no máximo 30%')
        error = true
      }
    }

    if (error) {
      return
    }

    handleSave()
  }

  const handleEnableOrDisableIndicatedComission = async (
    item: TIndicatedTenantItem
  ) => {
    setSending(true)
    try {
      let response = await IndicatedController.enableOrDisableIndicated({
        data: {
          indicationOfId: tenantSettings.tenantId,
          tenantId: item.tenantId,
          value: !item.isEnableCommissions,
        },
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        getIndicatedList(tenantSettings.tenantId)
      }
    } finally {
      setSending(false)
    }
  }

  const handleCloseIndicated = () => {
    setErrorCurrentIndicatedValue('')
    setCurrentIndicated(null)
  }

  const handleOpenIndicated = (value: boolean) => {
    setErrorCurrentIndicatedValue('')
    if (!value) {
      handleCloseIndicated()
    }
  }

  const handleSaveIndicated = async () => {
    setErrorCurrentIndicatedValue('')
    if (!currentIndicated) {
      setError('Nenhum indicado selecionado')
      return
    }

    const indicatedComissionsPercentage = maskPercentage(
      currentIndicated.indicatedComissionsPercentage ?? '0'
    )
      .replaceAll('.', '')
      .replaceAll(',', '.')
    currentIndicated.indicatedComissionsPercentage = Number(
      indicatedComissionsPercentage
    )
    if (currentIndicated.indicatedComissionsPercentage > 30) {
      if (!currentIndicated.isEnableCommissions) {
        currentIndicated.indicatedComissionsPercentage = 0
      } else {
        setErrorCurrentIndicatedValue('O valor precisa ser no máximo 30%')
        return
      }
    }

    setSending(true)
    try {
      let response = await IndicatedController.updateIndicatedTenant({
        data: {
          tenantId: currentIndicated.tenantId,
          comissionsPercentage: currentIndicated.indicatedComissionsPercentage,
          indicatedComissionLevelEnum:
            currentIndicated.indicatedComissionLevelEnum,
          receiveComissionUntilDateOf:
            currentIndicated.receiveComissionUntilDateOf,
        },
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleCloseIndicated()
        getIndicatedList(tenantSettings.tenantId)
      }
    } finally {
      setSending(false)
    }
  }

  const handleChangeCopyRecipientId = async () => {
    await Hosts.copyTextToClipboard(tenantSettings.recipientId ?? '')
    setMessage('Código do recebedor copiado')
  }

  const handleOpenRecipient = () => {
    Hosts.openNewBlank(recipientUrl)
  }

  const handleChangeImmediateWithdrawal = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorImmediateWithdrawal('')
    const immediateWithdrawal = maskPercentage(e.target.value)
      .replaceAll('.', '')
      .replaceAll(',', '.')
    setTenantSettings({
      ...tenantSettings,
      immediateWithdrawal: Number(immediateWithdrawal),
    })
  }

  const handleChangePlataformFee = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorImmediateWithdrawal('')
    const plataformFee = maskPercentage(e.target.value)
      .replaceAll('.', '')
      .replaceAll(',', '.')
    setTenantSettings({
      ...tenantSettings,
      plataformFee: Number(plataformFee),
    })
  }

  const handleChangeIndicatedComissionsRateValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorIndicatedValue('')
    const value = maskPercentage(e.target.value)
      .replaceAll('.', '')
      .replaceAll(',', '.')
    setTenantSettings({
      ...tenantSettings,
      indicatedComissionsRateValue: Number(value),
    })
  }

  const handleChangeAnticipationD2Fee = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorAnticipationD2Fee('')
    const taxD2 = maskPercentage(e.target.value)
      .replaceAll('.', '')
      .replaceAll(',', '.')
    setTenantSettings({
      ...tenantSettings,
      anticipationD2Fee: Number(taxD2),
    })
  }

  const handleChangeAnticipationD15Fee = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorAnticipationD15Fee('')
    const taxD15 = maskPercentage(e.target.value)
      .replaceAll('.', '')
      .replaceAll(',', '.')
    setTenantSettings({
      ...tenantSettings,
      anticipationD15Fee: Number(taxD15),
    })
  }

  const handleChangeSalesEnabled = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const salesEnabled = e.target.checked
    setSending(true)
    try {
      let response = await TenantController.salesEnabled({
        tenantId: tenantSettings.tenantId,
        enabled: salesEnabled,
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        setTenantSettings({
          ...tenantSettings,
          salesEnabled,
        })
        executeUserSettings()
      }
    } finally {
      setSending(false)
    }
  }

  const handleChangeWithdrawlsEnabled = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const withdrawlsEnabled = e.target.checked
    setSending(true)
    try {
      let response = await TenantController.withdrawlsEnabled({
        tenantId: tenantSettings.tenantId,
        enabled: withdrawlsEnabled,
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        setTenantSettings({
          ...tenantSettings,
          withdrawlsEnabled,
        })
        executeUserSettings()
      }
    } finally {
      setSending(false)
    }
  }

  const handleChangeEnableRegisterProducts = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const enableRegisterProducts = e.target.checked
    setSending(true)
    try {
      let response = await TenantController.enableRegisterProducts({
        tenantId: tenantSettings.tenantId,
        enabled: enableRegisterProducts,
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        setTenantSettings({
          ...tenantSettings,
          enableRegisterProducts,
        })
        executeUserSettings()
      }
    } finally {
      setSending(false)
    }
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleClose}
      setOpen={setOpen}
      title="Configurações do usuário"
      subtitle={`${tenantSettings.tenantName} (${tenantSettings.tenantEmail})`}
      buttons={[
        { title: 'Cancelar', onClick: handleClose, type: 'negative' },
        { title: 'Salvar', onClick: handleChangeSave, type: 'positive' },
      ]}
      buttonsDisabled={sending}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Geral" {...a11yProps(0)} />
            <Tab label="Valores" {...a11yProps(1)} />
            <Tab label="Indicados" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          <UserSettingGeneral
            tenantSettings={tenantSettings}
            settings={settings}
            loadingSettings={loadingSettings}
            handleChangeCopyRecipientId={handleChangeCopyRecipientId}
            handleOpenRecipient={handleOpenRecipient}
            handleChangeSalesEnabled={handleChangeSalesEnabled}
            handleChangeWithdrawlsEnabled={handleChangeWithdrawlsEnabled}
            handleChangeEnableRegisterProducts={
              handleChangeEnableRegisterProducts
            }
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <UserSettingValues
            tenantSettings={tenantSettings}
            errorAnticipationD15Fee={errorAnticipationD15Fee}
            errorAnticipationD2Fee={errorAnticipationD2Fee}
            errorImmediateWithdrawal={errorImmediateWithdrawal}
            errorIndicatedValue={errorIndicatedValue}
            errorPlatformFee={errorPlatformFee}
            setTenantSettings={setTenantSettings}
            handleChangeAnticipationD15Fee={handleChangeAnticipationD15Fee}
            handleChangeAnticipationD2Fee={handleChangeAnticipationD2Fee}
            handleChangeImmediateWithdrawal={handleChangeImmediateWithdrawal}
            handleChangeIndicatedComissionsRateValue={
              handleChangeIndicatedComissionsRateValue
            }
            handleChangePlataformFee={handleChangePlataformFee}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          {loading && <CircularProgress />}
          {!loading && (
            <Box
              width="100%"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              sx={{ pt: 2 }}
            >
              {indicateds.length <= 0 && (
                <Typography textAlign="center">
                  Nenhum indicado cadastrado para esse usuário.
                </Typography>
              )}
              {indicateds.length > 0 && (
                <Paper
                  sx={{
                    width: '100%',
                    mb: 2,
                    backgroundColor: grey[50],
                    boxShadow: '0',
                    p: 1,
                  }}
                >
                  <DataTable
                    columns={columns}
                    data={dataPagination}
                    page={0}
                    rowsPerPage={0}
                    hasPagination={false}
                    onPageChange={() => {}}
                    onRowsPerPageChange={() => {}}
                  />
                </Paper>
              )}
            </Box>
          )}
        </TabPanel>

        <SwipeableDrawerRightDialog
          open={currentIndicated !== null}
          onClose={handleCloseIndicated}
          setOpen={handleOpenIndicated}
          title="Editar configuração do indicado"
          subtitle={`${currentIndicated?.name} (${currentIndicated?.email})`}
          buttons={[
            {
              title: 'Cancelar',
              onClick: handleCloseIndicated,
              type: 'negative',
            },
            { title: 'Salvar', onClick: handleSaveIndicated, type: 'positive' },
          ]}
          buttonsDisabled={sending}
        >
          <Box sx={{ width: '100%', pt: 3, px: 3 }}>
            <Stack direction="row" spacing={2} width="100%">
              <FormControl fullWidth size="small">
                <InputLabel id="days-select-label">Nível</InputLabel>
                <Select
                  variant="outlined"
                  labelId="days-select-label"
                  label="Nível"
                  value={
                    currentIndicated?.indicatedComissionLevelEnum ??
                    TIndicatedComissionLevelEnum.None
                  }
                  onChange={(e) => {
                    const indicatedComissionLevelEnum = e.target
                      .value as TIndicatedComissionLevelEnum
                    const indicatedComissionsPercentage =
                      indicatedComissionLevelEnum ===
                      TIndicatedComissionLevelEnum.Basic
                        ? 5
                        : indicatedComissionLevelEnum ===
                            TIndicatedComissionLevelEnum.Gold
                          ? 10
                          : indicatedComissionLevelEnum ===
                              TIndicatedComissionLevelEnum.Platinum
                            ? 15
                            : 0
                    setCurrentIndicated({
                      ...currentIndicated!,
                      indicatedComissionLevelEnum,
                      indicatedComissionsPercentage,
                    })
                  }}
                >
                  {IndicatedFunctions.indicatedComissionLevels.map((level) => (
                    <MenuItem value={level}>
                      {IndicatedFunctions.getIndicatedComissionLevelString(
                        level
                      )}
                    </MenuItem>
                  ))}
                </Select>
                <Stack marginTop={'10px'}>
                  <BasicDatePicker
                    value={currentIndicated?.receiveComissionUntilDateOf}
                    setValue={(value) =>
                      setCurrentIndicated({
                        ...currentIndicated!,
                        receiveComissionUntilDateOf: value ?? new Date(),
                      })
                    }
                  />
                </Stack>
              </FormControl>
              <Box width="100%">
                <OutlinedInput
                  fullWidth
                  size="small"
                  type="text"
                  value={maskPercentage(
                    currentIndicated?.indicatedComissionsPercentage ?? 0
                  )}
                  onChange={(e) => {
                    setErrorIndicatedValue('')
                    const value = maskPercentage(e.target.value)
                      .replaceAll('.', '')
                      .replaceAll(',', '.')
                    setCurrentIndicated({
                      ...currentIndicated!,
                      indicatedComissionsPercentage: Number(value),
                    })
                  }}
                  endAdornment={
                    <HtmlTooltip
                      title={
                        <Typography>
                          Percentual a receber de comissão para cada indicado
                          cadastrado. Podendo ser de 0% até 30%.
                        </Typography>
                      }
                    >
                      <InfoIcon fontSize="small" sx={{ color: 'grey' }} />
                    </HtmlTooltip>
                  }
                  error={errorCurrentIndicatedValue !== ''}
                />

                {errorCurrentIndicatedValue !== '' && (
                  <Typography variant="caption" color={red[700]}>
                    {errorCurrentIndicatedValue}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Box>
        </SwipeableDrawerRightDialog>
      </Box>
    </SwipeableDrawerRightDialog>
  )
}

export default UserSetting
