import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  ListItemButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import { RecipientStatus } from '../../../../settings/TenantBalanceWithdraw/RecipientStatus'
import SwitchRoundSmall from '../../../../../components/Switch/SwitchRoundSmall'
import {
  ITenantBalance,
  TTenantSettings,
} from '../../../../../core/types/Tenant'
import { HtmlTooltip } from '../../../../../components/Tooltip/HtmlTooltip'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { BalanceDarkCard } from '../../../../settings/TenantBalanceWithdraw/BalanceDarkCard'
import { useState } from 'react'
import { BalanceLightCard } from '../../../../settings/TenantBalanceWithdraw/BalanceLightCard'
import { TenantController } from '../../../../../core/controllers/TenantController'
import { IErrorResponse } from '../../../../../core/types/ErrorResponse'
import { error } from 'console'

export type IUserSettingGeneralChangeEvent = (
  e: React.ChangeEvent<HTMLInputElement>
) => Promise<void>

export interface IUserSettingGeneralProps {
  tenantSettings: TTenantSettings
  loadingSettings: boolean
  settings: TTenantSettings | null
  handleChangeCopyRecipientId: () => void
  handleOpenRecipient: () => void
  handleChangeSalesEnabled: IUserSettingGeneralChangeEvent
  handleChangeWithdrawlsEnabled: IUserSettingGeneralChangeEvent
  handleChangeEnableRegisterProducts: IUserSettingGeneralChangeEvent
}

const UserSettingGeneral = ({
  tenantSettings,
  loadingSettings,
  settings,
  handleChangeCopyRecipientId,
  handleOpenRecipient,
  handleChangeSalesEnabled,
  handleChangeWithdrawlsEnabled,
  handleChangeEnableRegisterProducts,
}: IUserSettingGeneralProps) => {
  const [data, setData] = useState<ITenantBalance | undefined>(undefined)
  const [previewWithdraw, setPreviewWithdraw] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChangePreviewWithdraw = async () => {
    if (!previewWithdraw) {
      setLoading(true)
      try {
        const response = await TenantController.getBalance({
          tenantId: tenantSettings.tenantId,
        })
        const responseError = response as IErrorResponse
        const responseData = response as ITenantBalance

        if (responseError.code) {
          //setError(responseError.error)
        } else {
          setData(responseData)
        }
      } finally {
        setLoading(false)
      }
    }

    setPreviewWithdraw(!previewWithdraw)
  }

  return (
    <Stack direction="column" spacing={0} sx={{ py: 1 }}>
      <ListItemDefault
        title="Código do Recebedor"
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Box>
            {(tenantSettings.recipientId ?? '') !== '' && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <ListItemButton onClick={handleChangeCopyRecipientId}>
                  <Typography variant="subtitle2">
                    {tenantSettings.recipientId}
                  </Typography>
                </ListItemButton>
                <HtmlTooltip title="Copiar código do recebedor">
                  <IconButton
                    size="small"
                    onClick={handleChangeCopyRecipientId}
                  >
                    <img src="/assets/icons/copy/copy.svg" alt="copy" />
                  </IconButton>
                </HtmlTooltip>
                <HtmlTooltip title="Abrir recebedor na Pagar.Me">
                  <IconButton size="small" onClick={handleOpenRecipient}>
                    <OpenInNewIcon color="primary" />
                  </IconButton>
                </HtmlTooltip>
              </Stack>
            )}
          </Box>
        }
      />
      {(tenantSettings.recipientId ?? '') !== '' && (
        <ListItemDefault
          title="Status do Recebedor"
          widthTitlePercentage={40}
          variant="compact"
          value={
            !loadingSettings ? (
              <RecipientStatus status={settings?.recipientStatus ?? ''} />
            ) : (
              <CircularProgress size={27} />
            )
          }
        />
      )}
      <ListItemDefault
        title={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings.salesEnabled ?? false}
                onChange={handleChangeSalesEnabled}
              />
            }
            label={<Typography variant="subtitle2">Permite vender</Typography>}
            sx={{ width: '100%' }}
          />
        }
        widthTitlePercentage={50}
        variant="compact"
        value={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings.withdrawlsEnabled ?? false}
                onChange={handleChangeWithdrawlsEnabled}
              />
            }
            label={
              <Typography variant="subtitle2">
                Permite realizar saques
              </Typography>
            }
            sx={{ width: '100%' }}
          />
        }
      />
      <ListItemDefault
        title={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings.enableRegisterProducts ?? false}
                onChange={handleChangeEnableRegisterProducts}
              />
            }
            label={
              <Typography variant="subtitle2">
                Permite cadastrar produtos
              </Typography>
            }
            sx={{ width: '100%' }}
          />
        }
        widthTitlePercentage={100}
        variant="compact"
        value=""
      />
      <ListItemDefault
        title=""
        widthTitlePercentage={0.01}
        variant="compact"
        value={
          <>
            {!loading && (
              <Button
                fullWidth
                onClick={handleChangePreviewWithdraw}
                sx={{ textTransform: 'none' }}
              >
                {previewWithdraw ? 'Ocultar' : 'Visualizar'} saldo
              </Button>
            )}
            {previewWithdraw && (
              <Stack direction="row" spacing={1} alignItems="center">
                <BalanceDarkCard balance={data?.availableAmount ?? 0} />
                <BalanceLightCard balance={data?.totalAmount ?? 0} />
              </Stack>
            )}
            {loading && (
              <Stack spacing={1} sx={{ p: 1 }}>
                <Skeleton variant="rounded" width="100%" height="40px" />
                <Skeleton variant="rounded" width="100%" height="70px" />
              </Stack>
            )}
          </>
        }
      />
    </Stack>
  )
}

export default UserSettingGeneral
