import * as React from 'react'
import Paper from '@mui/material/Paper'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
  Button,
  Chip,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import MoreIcon from '@mui/icons-material/AddCircleOutline'
import { useWebhookSends } from '../../../core/hooks/useWebhookSends'
import { dateTimeString } from '../../../core/functions/dateTime'
import { TWebhookGenerateEnum, TWebhookSend } from '../../../core/types/Webhook'
import ReactJson from 'react-json-view'
import Hosts from '../../../core/functions/hosts'
import WebhookFunction from '../../../core/functions/webhooks'
import WebhookController from '../../../core/controllers/WebhookController'
import { IErrorResponse } from '../../../core/types/ErrorResponse'
import TablePaginationDefault from '../../../components/Tables/TablePaginationDefault'
import InputSearch from '../../../components/Inputs/InputSearch'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import AlertDefault from '../../../components/Alerts'

interface Column {
  id:
    | 'createdDate'
    | 'event'
    | 'identifier'
    | 'productName'
    | 'customerName'
    | 'url'
    | 'returnCode'
    | 'id'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'createdDate', label: 'Criado', minWidth: 80 },
  { id: 'event', label: 'Tipo', minWidth: 60 },
  {
    id: 'identifier',
    label: 'Webhook',
    minWidth: 80,
  },
  {
    id: 'productName',
    label: 'Produto',
    minWidth: 100,
  },
  {
    id: 'customerName',
    label: 'Cliente',
    minWidth: 100,
  },
  {
    id: 'url',
    label: 'URL',
    minWidth: 80,
  },
  {
    id: 'returnCode',
    label: 'Status',
    minWidth: 80,
  },
  {
    id: 'id',
    label: 'Ação',
    minWidth: 60,
  },
]

export default function WebhookSendsPage() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [event, setEvent] = React.useState<TWebhookGenerateEnum>()
  const [status, setStatus] = React.useState('')
  const [copied, setCopied] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [webhookSend, setWebhookSend] = React.useState<TWebhookSend>()
  const [jsonView, setJsonView] = React.useState('send')
  const [sending, setSending] = React.useState(false)
  const [errorSend, setErrorSend] = React.useState('')

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useWebhookSends({
    initialExecute: true,
    page,
    rows: rowsPerPage,
    search,
    event,
    status,
  })

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data, rowsPerPage])

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleViewJson = (send: TWebhookSend) => {
    setJsonView('send')
    setWebhookSend(send)
    setOpen(true)
  }

  const handleCloseViewJson = () => {
    setWebhookSend(undefined)
    setOpen(false)
  }

  const handleResend = async () => {
    if (webhookSend) {
      try {
        setSending(true)
        const response = await WebhookController.resendWebhookSend({
          webhookSendId: webhookSend.id,
        })

        const data = response as IErrorResponse
        if (data.error) {
          setErrorSend(data.error)
          return
        }

        execute()
      } finally {
        setSending(false)
      }
    }
  }

  const SendStatus = ({ code }: { code?: string }) => {
    const status = Hosts.returnCodeString(code)
    const returnCode = code || ''
    return (
      <Chip
        size="small"
        label={status}
        color={
          returnCode.startsWith('1')
            ? 'info'
            : returnCode.startsWith('2')
              ? 'success'
              : returnCode.startsWith('3')
                ? 'warning'
                : returnCode.startsWith('4') || returnCode.startsWith('5')
                  ? 'error'
                  : 'default'
        }
      />
    )
  }

  const ViewJSON = () => {
    var jstring: string | null = null
    try {
      if (jsonView === 'send' && webhookSend?.jsonSend) {
        jstring = webhookSend?.jsonSend
      }

      if (jsonView === 'return' && webhookSend?.jsonReturn) {
        jstring = webhookSend?.jsonReturn
      }

      const json = JSON.parse(jstring || '{}')
      return <ReactJson src={json} />
    } catch (e) {
      return <Typography>{jstring || 'Não existe'}</Typography>
    }
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Webhooks Enviados
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <InputSearch
        name="Filtrar por nome"
        setSearch={setSearch}
        execute={execute}
      />

      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="status-adornment-filter">
            Filtrar por status
          </InputLabel>
          <OutlinedInput
            id="status-adornment-filter"
            onChange={(e) => setStatus(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle search"
                  onClick={execute}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Filtrar por nome"
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="days-select-label">Tipo</InputLabel>
          <Select
            variant="outlined"
            labelId="days-select-label"
            label="Últimos"
            value={event}
            onChange={(e) => setEvent(Number(e.target.value))}
          >
            <MenuItem value={undefined}>Nenhum</MenuItem>
            <MenuItem value={undefined}>
              <optgroup label="Eventos únicos"></optgroup>
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.BoletoGenerated}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.BoletoGenerated
              )}
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.PixGenerated}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.PixGenerated
              )}
            </MenuItem>
            <MenuItem value={undefined}>
              <optgroup label="Compras"></optgroup>
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.ApprovedPurchase}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.ApprovedPurchase
              )}
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.PurchaseDeclined}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.PurchaseDeclined
              )}
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.Chargeback}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.Chargeback
              )}
            </MenuItem>
            {/* <MenuItem value={TWebhookEventEnum.ApprovedPurchaseComplete}>
                            {WebhookFunction.statusString(TWebhookEventEnum.ApprovedPurchaseComplete)}
                        </MenuItem> */}
            {/* <MenuItem value={TWebhookEventEnum.PurchaseDeclinedBlocked}>
                            {WebhookFunction.statusString(TWebhookEventEnum.PurchaseDeclinedBlocked)}
                        </MenuItem> */}
            {/* <MenuItem value={TWebhookEventEnum.PurchaseDeclinedNoFunds}>
                            {WebhookFunction.statusString(TWebhookEventEnum.PurchaseDeclinedNoFunds)}
                        </MenuItem> */}
            <MenuItem value={TWebhookGenerateEnum.ExpiredPurchaseExpired}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.ExpiredPurchaseExpired
              )}
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.WaitingPayment}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.WaitingPayment
              )}
            </MenuItem>
            {/* <MenuItem value={TWebhookEventEnum.ProcessingTransaction}>
                            {WebhookFunction.statusString(TWebhookEventEnum.ProcessingTransaction)}
                        </MenuItem> */}
            <MenuItem value={TWebhookGenerateEnum.Protested}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.Protested
              )}
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.Refunded}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.Refunded
              )}
            </MenuItem>
            <MenuItem value={undefined}>
              <optgroup label="Assinaturas"></optgroup>
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.SubscriptionActive}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.SubscriptionActive
              )}
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.SubscriptionCanceled}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.SubscriptionCanceled
              )}
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.SubscriptionBlocked}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.SubscriptionBlocked
              )}
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.SubscriptionCompleted}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.SubscriptionCompleted
              )}
            </MenuItem>
            <MenuItem value={undefined}>
              <optgroup label="Outros"></optgroup>
            </MenuItem>
            <MenuItem value={TWebhookGenerateEnum.AbandonedCheckout}>
              {WebhookFunction.statusGenerateString(
                TWebhookGenerateEnum.AbandonedCheckout
              )}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id.toString()}
                  >
                    {columns.map((column) => {
                      const value = row[column.id]
                      if (column.id === 'createdDate') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {dateTimeString(row.createdDate)}
                          </TableCell>
                        )
                      }
                      if (column.id === 'event') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {WebhookFunction.statusGenerateString(row.event)}
                          </TableCell>
                        )
                      }
                      if (column.id === 'customerName') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Stack direction="column">
                              <Typography variant="subtitle1">
                                {row.customerName}
                              </Typography>
                              <Typography variant="subtitle2">
                                {row.customerEmail}
                              </Typography>
                            </Stack>
                          </TableCell>
                        )
                      }
                      if (column.id === 'returnCode') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <SendStatus code={row.returnCode} />
                          </TableCell>
                        )
                      }
                      if (column.id === 'id') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <IconButton
                              size="small"
                              onClick={() => handleViewJson(row)}
                            >
                              <MoreIcon />
                            </IconButton>
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : (value || '').toString()}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Box sx={{ height: 80 }} />

      <LoadingBackdrop open={loading || sending} />

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />

      <SwipeableDrawerRightDialog
        open={open}
        onClose={handleCloseViewJson}
        setOpen={setOpen}
        title="Webhook JSON"
        subtitle={dateTimeString(webhookSend?.createdDate)}
        /*actions={
                    <Stack direction="row" spacing={1} sx={{ p: 1 }} justifyContent="end">
                        <Button variant="outlined" disabled={sending} onClick={handleCloseViewJson}>
                            Fechar
                        </Button>
                        <Button variant="contained" disabled={sending} onClick={handleResend}>
                            Reenviar
                        </Button>
                    </Stack>
                }*/
        buttons={[
          { title: 'Fechar', onClick: handleCloseViewJson, type: 'negative' },
          { title: 'Reenviar', onClick: handleResend, type: 'positive' },
        ]}
        buttonsDisabled={sending}
      >
        <Stack direction="column" sx={{ width: '100%' }}>
          <Stack direction="row" spacing={2} sx={{ p: 2 }}>
            <SendStatus code={webhookSend?.returnCode} />
          </Stack>

          <Stack direction="row" spacing={2} sx={{ p: 2 }}>
            <Button
              size="small"
              variant={jsonView === 'send' ? 'contained' : 'outlined'}
              onClick={() => setJsonView('send')}
            >
              Envio
            </Button>
            <Button
              size="small"
              variant={jsonView === 'return' ? 'contained' : 'outlined'}
              onClick={() => setJsonView('return')}
            >
              Retorno
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={async () => {
                const json =
                  jsonView === 'send'
                    ? webhookSend?.jsonSend
                    : webhookSend?.jsonReturn
                if (json) {
                  await Hosts.copyTextToClipboard(json)
                  setCopied(true)
                }
              }}
            >
              Copiar JSON
            </Button>
          </Stack>

          <ViewJSON />
        </Stack>
      </SwipeableDrawerRightDialog>
    </Box>
  )
}
