import { Box } from '@mui/material'
import { TSubscriptionDetails } from '../../../../../../core/types/CheckoutOrder'
import ListItemDefault from '../../../../../../components/ListItem/ListItemDefault'
import {
  maskCpfCnpjNumber,
  maskPhoneNumber,
} from '../../../../../../masks/masks'

export interface IOrderDetailCustomerProp {
  subscription?: TSubscriptionDetails
}

const SubscriptionOrderDetailCustomer = ({
  subscription,
}: IOrderDetailCustomerProp) => {
  return (
    <Box>
      <ListItemDefault title="Nome" value={subscription?.name} />
      <ListItemDefault title="E-mail" value={subscription?.email} />
      <ListItemDefault
        title="Celular"
        value={maskPhoneNumber(subscription?.orderDetails?.customerPhone)}
      />
      <ListItemDefault
        title="CPF"
        value={maskCpfCnpjNumber(subscription?.orderDetails.customerDocument)}
      />
    </Box>
  )
}

export default SubscriptionOrderDetailCustomer
