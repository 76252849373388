import { Box, Skeleton, Stack } from '@mui/material'
import {
  TSubscription,
  TSubscriptionDetails,
} from '../../../../../core/types/CheckoutOrder'
import { maskCpfCnpjNumber, maskPhoneNumber } from '../../../../../masks/masks'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'

export interface IOrderDetailCustomerProp {
  checkoutOrder?: TSubscription
  details?: TSubscriptionDetails
  loading: boolean
}

const SubscriptionDetailCustomer = ({
  checkoutOrder,
  details,
  loading,
}: IOrderDetailCustomerProp) => {
  if (loading) {
    return (
      <Stack spacing={2} sx={{ p: 2 }}>
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
      </Stack>
    )
  }

  return (
    <Box>
      <ListItemDefault title="Nome" value={checkoutOrder?.name} />
      <ListItemDefault title="E-mail" value={checkoutOrder?.email} />
      <ListItemDefault
        title="Celular"
        value={maskPhoneNumber(details?.phone)}
      />
      <ListItemDefault title="CPF" value={maskCpfCnpjNumber(details?.phone)} />
    </Box>
  )
}

export default SubscriptionDetailCustomer
