import { Box, Stack, Tab, Tabs } from '@mui/material'
import React from 'react'
import {
  TSubscriptionDetails,
  TSubscriptionTransaction,
} from '../../../../../core/types/CheckoutOrder'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { a11yProps, TabPanel } from '../../../../../components/Tabs'
import SubscriptionOrderDetailCustomer from './Tabs/SubscriptionOrderDetailCustomer'
import SubscriptionOrderDetailOrder from './Tabs/SubscriptionOrderDetailOrder'
import SubscriptionOrderDetailValues from './Tabs/SubscriptionOrderDetailValues'

export interface ISubscriptionOrderDetailsProp {
  data?: TSubscriptionDetails
  transaction?: TSubscriptionTransaction
  open: boolean
  //isOpened: boolean
  handleClose: () => void
  setOpen: (value: boolean) => void
  /*setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
  execute: () => void
  setCopied: (value: boolean) => void*/
}

const SubscriptionOrderDetails = ({
  data,
  transaction,
  open,
  //isOpened,
  handleClose,
  setOpen,
  /*setSending,
  setSuccess,
  setError,
  execute,
  setCopied,*/
}: ISubscriptionOrderDetailsProp) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  React.useEffect(() => {
    if (data && data.id) {
      setValue(0)
    }
  }, [data])

  return (
    <SwipeableDrawerRightDialog
      hideButtons
      open={open}
      onClose={handleClose}
      setOpen={setOpen}
      backgroundColor="#fafafa"
      title="Detalhes da Transação"
      subtitle="Visualize os detalhes da cobrança da assinatura"
    >
      <>
        <Stack direction="column" sx={{ width: '100%' }}>
          <Box>
            <Stack
              direction="row"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              alignItems="center"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
                sx={{ flexGrow: 1 }}
              >
                <Tab label="Venda" {...a11yProps(0)} />
                <Tab label="Cliente" {...a11yProps(1)} />
                <Tab label="Valores" {...a11yProps(2)} />
              </Tabs>
            </Stack>
            <TabPanel value={value} index={0}>
              <SubscriptionOrderDetailOrder
                subscription={data}
                transaction={transaction}
                /*isOpened={isOpened}
                setSending={setSending}
                setSuccess={setSuccess}
                setError={setError}
                execute={execute}
                setCopied={setCopied}*/
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SubscriptionOrderDetailCustomer subscription={data} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <SubscriptionOrderDetailValues subscription={data} />
            </TabPanel>
          </Box>
        </Stack>
      </>
    </SwipeableDrawerRightDialog>
  )
}

export default SubscriptionOrderDetails
