import {
  Box,
  Button,
  Chip,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {
  TSubscription,
  TSubscriptionDetails,
  TSubscriptionTransaction,
} from '../../../../../core/types/CheckoutOrder'
import Convertions from '../../../../../core/functions/convertions'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { dateTimeString } from '../../../../../core/functions/dateTime'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import { checkoutOrderStatusColorMap } from '../../../Orders/CheckoutOrderConsts'
import SubscriptionOrderDetails from '../CheckoutOrderDetails'
import { useRef, useState } from 'react'

export interface IOrderDetailPaymentsProp {
  checkoutOrder?: TSubscription
  details?: TSubscriptionDetails
  loading: boolean
}

const SubscriptionDetailPayments = ({
  checkoutOrder,
  details,
  loading,
}: IOrderDetailPaymentsProp) => {
  const [open, setOpen] = useState(false)

  const transaction = useRef<TSubscriptionTransaction | undefined>(undefined)

  const handleChangeTransaction = (trans: TSubscriptionTransaction) => {
    transaction.current = trans
    setOpen(true)
  }

  if (loading) {
    return (
      <Stack spacing={2} sx={{ p: 2 }}>
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
      </Stack>
    )
  }

  return (
    <Box width="100%" sx={{ pt: 2 }}>
      <Box
        sx={{
          mx: 2,
          px: 2,
          py: 1,
          backgroundColor: 'divider',
          borderRadius: '4px',
        }}
      >
        <Grid container columns={18} width="100%">
          <Grid xs={8}>
            <Typography variant="subtitle2">Data e Hora</Typography>
          </Grid>
          <Grid xs={8}>
            <Typography variant="subtitle2" textAlign="end">
              Valor
            </Typography>
          </Grid>
          <Grid xs={2} textAlign="center"></Grid>
        </Grid>
      </Box>
      {details?.transactions.map((transaction, index) => {
        const colorConfig =
          checkoutOrderStatusColorMap[transaction.status] ||
          checkoutOrderStatusColorMap.default
        return (
          <ListItemDefault key={`paid-${index}`} title="" py={1}>
            <Grid container columns={18} width="100%">
              <Grid xs={8}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  alignContent="center"
                  height="100%"
                >
                  <Typography variant="body2">
                    {dateTimeString(transaction.createdDate)}
                  </Typography>
                  <Chip
                    label={Convertions.checkoutOrderStatusToString(
                      transaction.status
                    )}
                    size="small"
                    sx={{
                      backgroundColor: colorConfig.backgroundColor,
                      color: colorConfig.color,
                    }}
                  />
                </Stack>
              </Grid>
              <Grid xs={8}>
                <Stack
                  direction="row"
                  height="100%"
                  width="100%"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Typography variant="body2">{`${NumberFunctions.formatMoneyDefault(details.value)} / ${Convertions.productFrequencyToType(checkoutOrder?.frequency)}`}</Typography>
                </Stack>
              </Grid>
              <Grid xs={2}>
                <Stack
                  direction="row"
                  height="100%"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Tooltip title="Visualizar detalhes">
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        px: 1,
                        py: 0.5,
                        m: 0,
                        minWidth: 0,
                        borderRadius: '8px',
                      }}
                      onClick={() => handleChangeTransaction(transaction)}
                    >
                      <img
                        src="/assets/icons/eyes/eyeButtonBlack.svg"
                        alt="action-eye"
                        style={{ fontSize: '1.2rem', color: 'black' }}
                      />
                    </Button>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
          </ListItemDefault>
        )
      })}

      <SubscriptionOrderDetails
        open={open}
        setOpen={setOpen}
        handleClose={() => setOpen(false)}
        data={details}
        transaction={transaction.current}
      />
    </Box>
  )
}

export default SubscriptionDetailPayments
