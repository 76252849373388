import { Box, Button, Chip, Skeleton, Stack } from '@mui/material'
import {
  TSubscription,
  TSubscriptionDetails,
} from '../../../../../core/types/CheckoutOrder'
import Convertions from '../../../../../core/functions/convertions'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { dateTimeString } from '../../../../../core/functions/dateTime'
import { TProductVoucherValue } from '../../../../../core/types/Voucher'
import { useContext } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'
import { TUserType } from '../../../../../core/types/Auth'
import OrderController from '../../../../../core/controllers/OrderController'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import SubscriptionFunctions from '../../../../../core/functions/SubscriptionFunctions'

export interface ISubscriptionDetailOrderProp {
  checkoutOrder?: TSubscription
  details?: TSubscriptionDetails
  loading: boolean
  setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
  execute: () => void
  getDetails: (id: string) => Promise<void>
  setCopied: (value: boolean) => void
}

const SubscriptionDetailOrder = ({
  checkoutOrder,
  details,
  loading,
  setSending,
  setSuccess,
  setError,
  execute,
  getDetails,
  setCopied,
}: ISubscriptionDetailOrderProp) => {
  const { user } = useContext(AuthContext)

  const statusMap = SubscriptionFunctions.getStatusMap(checkoutOrder?.status)
  const { label, ...style } = statusMap

  const handleChangeUnlock = async () => {
    if (!checkoutOrder?.id) {
      setError('Nenhuma venda selecionada')
      return
    }

    setSending(true)
    try {
      const response = await OrderController.unlockSale({
        checkoutOrderId: checkoutOrder.id,
      })

      if (!response.success) {
        setError(response.error)
        return
      }

      setSuccess(true)
      await getDetails(checkoutOrder.id)
      execute()
    } finally {
      setSending(false)
    }
  }

  if (loading) {
    return (
      <Stack spacing={2} sx={{ p: 2 }}>
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
      </Stack>
    )
  }

  return (
    <Box>
      <ListItemDefault
        title="ID da assinatura"
        value={
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>{details?.codeId}</Box>
          </Stack>
        }
      />
      <ListItemDefault
        title="Pagamento"
        value={
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              {Convertions.methodTypeToString(checkoutOrder?.paymentyMethod)}
            </Box>
            <Chip label={label} size="small" sx={{ ...style }} />
          </Stack>
        }
      />
      {/*details?.errors && details.errors.length > 0 && (
        <Stack sx={{ direction: 'column', mt: 0.5, px: 2 }}>
          {details.errors.map((error) => (
            <Stack direction="column">
              <Alert severity="warning" sx={{ width: '100%', mb: 1 }}>
                <AlertTitle>
                  {Convertions.ckeckoutOrderDetailsError(error.type)} -{' '}
                  {dateTimeString(error.createdDate)}
                </AlertTitle>
                {Convertions.ckeckoutOrderDetailsDescriptionError(error.type)}
              </Alert>
            </Stack>
          ))}
        </Stack>
      )*/}

      <ListItemDefault
        title="Valor líquido"
        value={NumberFunctions.formatMoneyDefault(details?.value)}
      />
      <ListItemDefault title="Produto" value={checkoutOrder?.productName} />
      <ListItemDefault title="Oferta" value={details?.offerName ?? ''} />
      <ListItemDefault
        title="Frequência"
        value={Convertions.productFrequencyToString(checkoutOrder?.frequency)}
      />
      <ListItemDefault
        title="Início da assinatura"
        value={dateTimeString(details?.createdDate)}
      />
      <ListItemDefault
        title="Final da assinatura"
        value={dateTimeString(details?.closingDate)}
      />
      <ListItemDefault
        title="Próxima cobrança"
        value={dateTimeString(details?.nextPaymentDate)}
      />
      {details?.orderDetails?.voucherIdentifier !== undefined &&
        details?.orderDetails?.voucherIdentifier !== null &&
        details?.orderDetails?.voucherIdentifier !== '' && (
          <ListItemDefault
            title="Cupom de desconto"
            value={`${details?.orderDetails?.voucherIdentifier} - ${
              details?.orderDetails?.voucherType === TProductVoucherValue.value
                ? NumberFunctions.formatMoneyDefault(
                    details?.orderDetails?.voucherValue
                  )
                : NumberFunctions.toPercentage(
                    details?.orderDetails?.voucherValue
                  )
            }`}
          />
        )}

      {details?.orderDetails?.isSubscriptionPaymentCancel && (
        <ListItemDefault
          title="Cobrança da Recorrência"
          value={<Chip label="Cancelado" size="small" color="error" />}
        />
      )}
      {details?.orderDetails?.isBlocked && (
        <ListItemDefault title="" px={0} py={0}>
          <Box width="100%">
            <ListItemDefault
              title={
                <Chip label="Venda Bloqueada" size="small" color="error" />
              }
              value={
                details?.orderDetails?.reasonBlocked ??
                'Motivo não identificado'
              }
              viewDivider={false}
              px={0}
              py={0}
            />
            {user?.UserType === TUserType.SystemAdmin && (
              <Box sx={{ px: 2, pb: 2 }}>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={handleChangeUnlock}
                  sx={{ textTransform: 'none' }}
                >
                  Desbloquear venda
                </Button>
              </Box>
            )}
          </Box>
        </ListItemDefault>
      )}
    </Box>
  )
}

export default SubscriptionDetailOrder
