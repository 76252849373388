import React from 'react'
import TagFunctions from '../../../core/functions/TagFunctions'
import { Container } from '@mui/material'

export default function ProductAcceptForm() {
  const active = React.useRef(false)

  React.useEffect(() => {
    if (!active.current) {
      active.current = true
      TagFunctions.addScriptHeader(
        `const bodyScript=document.querySelector('body'),hasScript=document.querySelector('#respondi_src');if(!hasScript){const script=document.createElement('script');script.setAttribute("async", "");document.body.appendChild(script),script.id='respondi_src',script.src='https://embed.respondi.app/embed.js';}`
      )
    }
  }, [active])

  return (
    <Container>
      <div
        data-respondi-container=""
        data-respondi-mode="regular"
        data-respondi-src="https://form.respondi.app/HgsoxJMS"
        data-respondi-width="100%"
        data-respondi-height="85vh"
      ></div>
    </Container>
  )
}
