import { TWebhookEventStatusEnum, TWebhookGenerateEnum } from '../types/Webhook'

const webhookEventEnumAll = [
  TWebhookGenerateEnum.BoletoGenerated,
  TWebhookGenerateEnum.PixGenerated,
  TWebhookGenerateEnum.PurchaseDeclined,
  TWebhookGenerateEnum.ApprovedPurchase,
  TWebhookGenerateEnum.Chargeback,
  TWebhookGenerateEnum.ExpiredPurchaseExpired,
  TWebhookGenerateEnum.WaitingPayment,
  TWebhookGenerateEnum.AbandonedCheckout,
  TWebhookGenerateEnum.Protested,
  TWebhookGenerateEnum.Refunded,
  TWebhookGenerateEnum.Affiliation,
  TWebhookGenerateEnum.SubscriptionActive,
  TWebhookGenerateEnum.SubscriptionCanceled,
  TWebhookGenerateEnum.SubscriptionDelayed,
  TWebhookGenerateEnum.SubscriptionBlocked,
  TWebhookGenerateEnum.SubscriptionCompleted,
]

const webhookByPayments = [
  {
    payment: 'Boleto',
    events: [
      TWebhookGenerateEnum.WaitingPayment,
      TWebhookGenerateEnum.ExpiredPurchaseExpired,
      TWebhookGenerateEnum.Protested,
      TWebhookGenerateEnum.Refunded,
      TWebhookGenerateEnum.SubscriptionActive,
      TWebhookGenerateEnum.SubscriptionCanceled,
      TWebhookGenerateEnum.SubscriptionDelayed,
      TWebhookGenerateEnum.SubscriptionBlocked,
      TWebhookGenerateEnum.SubscriptionCompleted,
    ],
  },
  {
    payment: 'Pix',
    events: [
      TWebhookGenerateEnum.WaitingPayment,
      TWebhookGenerateEnum.ExpiredPurchaseExpired,
      TWebhookGenerateEnum.Protested,
      TWebhookGenerateEnum.Refunded,
      TWebhookGenerateEnum.SubscriptionActive,
      TWebhookGenerateEnum.SubscriptionCanceled,
      TWebhookGenerateEnum.SubscriptionDelayed,
      TWebhookGenerateEnum.SubscriptionBlocked,
      TWebhookGenerateEnum.SubscriptionCompleted,
    ],
  },
  {
    payment: 'CreditCard',
    events: [
      TWebhookGenerateEnum.ApprovedPurchase,
      TWebhookGenerateEnum.PurchaseDeclined,
      TWebhookGenerateEnum.Chargeback,
      TWebhookGenerateEnum.Protested,
      TWebhookGenerateEnum.Refunded,
      TWebhookGenerateEnum.SubscriptionActive,
      TWebhookGenerateEnum.SubscriptionCanceled,
      TWebhookGenerateEnum.SubscriptionDelayed,
      TWebhookGenerateEnum.SubscriptionBlocked,
      TWebhookGenerateEnum.SubscriptionCompleted,
    ],
  },
  /*{
    payment: 'DebitCard',
    events: [
      TWebhookEventEnum.ApprovedPurchase,
      TWebhookEventEnum.PurchaseDeclined,
      TWebhookEventEnum.Chargeback,
      TWebhookEventEnum.Protested,
      TWebhookEventEnum.Refunded,
      TWebhookEventEnum.SubscriptionActive,
      TWebhookEventEnum.SubscriptionCanceled,
      TWebhookEventEnum.SubscriptionDelayed,
      TWebhookEventEnum.SubscriptionBlocked,
      TWebhookEventEnum.SubscriptionCompleted,
    ],
  },
  {
    payment: 'BankTransfer',
    events: [
      TWebhookEventEnum.PurchaseDeclined,
      TWebhookEventEnum.Protested,
      TWebhookEventEnum.Refunded,
    ],
  },*/
]

/*const statusString = (value?: TWebhookEventEnum) => {
  switch (value) {
    case TWebhookEventEnum.ApprovedPurchase:
      return 'Compra Aprovada'
    case TWebhookEventEnum.BoletoGenerated:
      return 'Boleto Gerado'
    case TWebhookEventEnum.PixGenerated:
      return 'PIX Gerado'
    case TWebhookEventEnum.Chargeback:
      return 'Estorno'
    case TWebhookEventEnum.PurchaseDeclined:
      return 'Compra Recusada'
    case TWebhookEventEnum.ExpiredPurchase:
      return 'Compra expirada'
    case TWebhookEventEnum.LatePurchase:
      return 'Recorrência em atraso'
    case TWebhookEventEnum.WaitingPayment:
      return 'Aguardando pagamento'
    case TWebhookEventEnum.AbandonedCheckout:
      return 'Compras abandonadas'
    case TWebhookEventEnum.Protested:
      return 'Solicitação reembolso'
    case TWebhookEventEnum.Refunded:
      return 'Reembolso'
    case TWebhookEventEnum.Affiliation:
      return 'Afiliação'
    case TWebhookEventEnum.SubscriptionActive:
      return 'Assinatura Ativa'
    case TWebhookEventEnum.SubscriptionCanceled:
      return 'Assinatura Cancelada'
    case TWebhookEventEnum.SubscriptionDelayed:
      return 'Assinatura em Atraso'
    case TWebhookEventEnum.SubscriptionBlocked:
      return 'Assinatura Bloqueada'
    case TWebhookEventEnum.SubscriptionCompleted:
      return 'Assinatura Concluída'
  }
}*/

const statusGenerateString = (value?: TWebhookGenerateEnum) => {
  switch (value) {
    case TWebhookGenerateEnum.BoletoGenerated:
      return 'Boleto Gerado'
    case TWebhookGenerateEnum.PixGenerated:
      return 'Pix Gerado'
    case TWebhookGenerateEnum.PurchaseDeclined:
      return 'Compra Negada'
    case TWebhookGenerateEnum.ApprovedPurchase:
      return 'Compra Aprovada'
    case TWebhookGenerateEnum.Chargeback:
      return 'Estorno'
    case TWebhookGenerateEnum.ApprovedPurchaseComplete:
      return 'Compra Aprovada Concluída'
    case TWebhookGenerateEnum.PurchaseDeclinedBlocked:
      return 'Compra Bloqueada'
    case TWebhookGenerateEnum.PurchaseDeclinedNoFunds:
      return 'Compra Recusada '
    case TWebhookGenerateEnum.ExpiredPurchaseExpired:
      return 'Compra Expirada'
    case TWebhookGenerateEnum.SubscriptionDelayed:
      return 'Recorrência em Atraso'
    case TWebhookGenerateEnum.WaitingPayment:
      return 'Aguardando Pagamento'
    case TWebhookGenerateEnum.ProcessingTransaction:
      return 'Processando Transação'
    case TWebhookGenerateEnum.AbandonedCheckout:
      return 'Compras Abandonadas'
    case TWebhookGenerateEnum.Protested:
      return 'Solicitação Reembolso'
    case TWebhookGenerateEnum.Refunded:
      return 'Reembolso'
    case TWebhookGenerateEnum.Affiliation:
      return 'Afiliação'
    case TWebhookGenerateEnum.SubscriptionActive:
      return 'Assinatura Ativa'
    case TWebhookGenerateEnum.SubscriptionCanceled:
      return 'Assinatura Cancelada'
    case TWebhookGenerateEnum.SubscriptionDelayed:
      return 'Assinatura em Atraso'
    case TWebhookGenerateEnum.SubscriptionBlocked:
      return 'Assinatura Bloqueada'
    case TWebhookGenerateEnum.SubscriptionCompleted:
      return 'Assinatura Concluída'
  }
}

const eventType = (value?: TWebhookGenerateEnum) => {
  switch (value) {
    case TWebhookGenerateEnum.ApprovedPurchase:
      return 'ApprovedPurchase'
    case TWebhookGenerateEnum.BoletoGenerated:
      return 'BoletoGenerated'
    case TWebhookGenerateEnum.PixGenerated:
      return 'PixGenerated'
    case TWebhookGenerateEnum.Chargeback:
      return 'Chargeback'
    case TWebhookGenerateEnum.PurchaseDeclined:
      return 'PurchaseDeclined'
    case TWebhookGenerateEnum.ExpiredPurchaseExpired:
      return 'ExpiredPurchase'
    case TWebhookGenerateEnum.WaitingPayment:
      return 'WaitingPayment'
    case TWebhookGenerateEnum.AbandonedCheckout:
      return 'AbandonedCheckout'
    case TWebhookGenerateEnum.Protested:
      return 'Protested'
    case TWebhookGenerateEnum.Refunded:
      return 'Refunded'
    case TWebhookGenerateEnum.Affiliation:
      return 'Affiliation'
    case TWebhookGenerateEnum.SubscriptionActive:
      return 'SubscriptionActive'
    case TWebhookGenerateEnum.SubscriptionCanceled:
      return 'SubscriptionCanceled'
    case TWebhookGenerateEnum.SubscriptionDelayed:
      return 'SubscriptionDelayed'
    case TWebhookGenerateEnum.SubscriptionBlocked:
      return 'SubscriptionBlocked'
    case TWebhookGenerateEnum.SubscriptionCompleted:
      return 'SubscriptionCompleted'
  }
  return ''
}

const eventStatus = (value?: TWebhookEventStatusEnum) => {
  if (!value) {
    return ''
  }

  const values = {
    [TWebhookEventStatusEnum.Abandoned]: 'Abandoned',
    [TWebhookEventStatusEnum.Approved]: 'Approved',
    [TWebhookEventStatusEnum.Blocked]: 'Blocked',
    [TWebhookEventStatusEnum.Chargeback]: 'Chargeback',
    [TWebhookEventStatusEnum.Complete]: 'Complete',
    [TWebhookEventStatusEnum.Expired]: 'Expired',
    [TWebhookEventStatusEnum.NoFunds]: 'NoFunds',
    [TWebhookEventStatusEnum.Overdue]: 'Overdue',
    [TWebhookEventStatusEnum.ProcessingTransaction]: 'ProcessingTransaction',
    [TWebhookEventStatusEnum.Protested]: 'Protested',
    [TWebhookEventStatusEnum.PurchaseDeclined]: 'PurchaseDeclined',
    [TWebhookEventStatusEnum.Refunded]: 'Refunded',
    [TWebhookEventStatusEnum.UnderAnalisys]: 'UnderAnalisys',
    [TWebhookEventStatusEnum.WaitingPayment]: 'WaitingPayment',
    [TWebhookEventStatusEnum.Affiliation]: 'Affiliation',
    [TWebhookEventStatusEnum.SubscriptionActive]: 'SubscriptionActive',
    [TWebhookEventStatusEnum.SubscriptionCanceled]: 'SubscriptionCanceled',
    [TWebhookEventStatusEnum.SubscriptionDelayed]: 'SubscriptionDelayed',
    [TWebhookEventStatusEnum.SubscriptionBlocked]: 'SubscriptionBlocked',
    [TWebhookEventStatusEnum.SubscriptionCompleted]: 'SubscriptionCompleted',
  }

  return values[value]
}

const chargeStatusString = (value?: TWebhookGenerateEnum) => {
  switch (value) {
    case TWebhookGenerateEnum.ApprovedPurchase:
      return 'all'
    case TWebhookGenerateEnum.BoletoGenerated:
      return 'boleto'
    case TWebhookGenerateEnum.PixGenerated:
      return 'pix'
    case TWebhookGenerateEnum.Chargeback:
      return 'credit_card | debit_card'
    case TWebhookGenerateEnum.PurchaseDeclined:
      return 'credit_card | debit_card'
    case TWebhookGenerateEnum.ExpiredPurchaseExpired:
      return 'boleto | pix'
    case TWebhookGenerateEnum.WaitingPayment:
      return 'boleto | pix'
    case TWebhookGenerateEnum.AbandonedCheckout:
      return 'all'
    case TWebhookGenerateEnum.Protested:
      return 'all'
    case TWebhookGenerateEnum.Refunded:
      return 'all'
  }
}

const getEventStatusEnum = (value?: TWebhookGenerateEnum) => {
  if (!value) {
    return TWebhookEventStatusEnum.WaitingPayment
  }

  const values = {
    [TWebhookGenerateEnum.BoletoGenerated]:
      TWebhookEventStatusEnum.WaitingPayment,
    [TWebhookGenerateEnum.AbandonedCheckout]: TWebhookEventStatusEnum.Abandoned,
    [TWebhookGenerateEnum.ApprovedPurchase]: TWebhookEventStatusEnum.Approved,
    [TWebhookGenerateEnum.Chargeback]: TWebhookEventStatusEnum.Chargeback,
    [TWebhookGenerateEnum.ExpiredPurchaseExpired]:
      TWebhookEventStatusEnum.Expired,
    [TWebhookGenerateEnum.PixGenerated]: TWebhookEventStatusEnum.WaitingPayment,
    [TWebhookGenerateEnum.Protested]: TWebhookEventStatusEnum.Protested,
    [TWebhookGenerateEnum.PurchaseDeclined]:
      TWebhookEventStatusEnum.PurchaseDeclined,
    [TWebhookGenerateEnum.Refunded]: TWebhookEventStatusEnum.Refunded,
    [TWebhookGenerateEnum.WaitingPayment]:
      TWebhookEventStatusEnum.WaitingPayment,
    [TWebhookGenerateEnum.Affiliation]: TWebhookEventStatusEnum.Affiliation,
    [TWebhookGenerateEnum.SubscriptionActive]:
      TWebhookEventStatusEnum.SubscriptionActive,
    [TWebhookGenerateEnum.SubscriptionCanceled]:
      TWebhookEventStatusEnum.SubscriptionCanceled,
    [TWebhookGenerateEnum.SubscriptionDelayed]:
      TWebhookEventStatusEnum.SubscriptionDelayed,
    [TWebhookGenerateEnum.SubscriptionBlocked]:
      TWebhookEventStatusEnum.SubscriptionBlocked,
    [TWebhookGenerateEnum.SubscriptionCompleted]:
      TWebhookEventStatusEnum.SubscriptionCompleted,
    [TWebhookGenerateEnum.ApprovedPurchaseComplete]:
      TWebhookEventStatusEnum.Complete,
    [TWebhookGenerateEnum.PurchaseDeclinedBlocked]:
      TWebhookEventStatusEnum.Blocked,
    [TWebhookGenerateEnum.PurchaseDeclinedNoFunds]:
      TWebhookEventStatusEnum.NoFunds,
    [TWebhookGenerateEnum.LatePurchaseOverdue]: TWebhookEventStatusEnum.Overdue,
    [TWebhookGenerateEnum.ProcessingTransaction]:
      TWebhookEventStatusEnum.ProcessingTransaction,
  }

  return values[value]
}

const WebhookFunction = {
  webhookEventEnumAll,
  //statusString,
  eventType,
  chargeStatusString,
  webhookByPayments,
  statusGenerateString,
  eventStatus,
  getEventStatusEnum,
}

export default WebhookFunction
