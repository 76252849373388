import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material'

interface ICheckboxTooltipProps {
  tooltip: string
  label: string
  checked: boolean
  onChecked: (value: boolean) => void
}

const CheckboxTooltip = ({
  tooltip,
  label,
  checked,
  onChecked,
}: ICheckboxTooltipProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChecked(event.target.checked)

  return (
    <Tooltip title={tooltip}>
      <FormControlLabel
        control={
          <Checkbox size="small" checked={checked} onChange={handleChange} />
        }
        label={
          <Typography
            color="#4A4B4E"
            fontSize="14px"
            lineHeight="18px"
            fontWeight={500}
          >
            {label}
          </Typography>
        }
      />
    </Tooltip>
  )
}

export default CheckboxTooltip
