export interface ITagsProp {
  script: HTMLScriptElement | null
  noscript?: HTMLElement | null
}

const emptyTags = (): ITagsProp => {
  const script = document.createElement('script')
  script.innerHTML = ''
  script.async = false
  return { script }
}

const addMetaHeader = (name: string, content: string): HTMLMetaElement => {
  const meta = document.createElement('meta')
  meta.name = name
  meta.content = content

  document.head.appendChild(meta)

  return meta
}

const addScriptHeader = (
  innerHTML: string,
  type?: string
): HTMLScriptElement | null => {
  try {
    const script = document.createElement('script')
    script.innerHTML = innerHTML
    script.async = false

    if (type && type !== '') {
      script.type = type
    }

    document.head.appendChild(script)

    return script
  } catch (e) {
    return null
  }
}

const addScriptHeaderAsync = (src: string): HTMLScriptElement => {
  const script = document.createElement('script')
  script.src = src
  script.async = true

  document.head.appendChild(script)

  return script
}

const addNoscriptBody = (src: string): HTMLElement => {
  const iframe = document.createElement('iframe')
  iframe.src = src
  iframe.height = '0'
  iframe.width = '0'

  const noscript = document.createElement('noscript')
  noscript.appendChild(iframe)

  document.body.appendChild(noscript)

  return noscript
}

const removeTags = ({ script, noscript }: ITagsProp) => {
  try {
    if (script) {
      document.head.removeChild(script)
    }
  } catch (e) {
    console.error(e)
  }

  if (noscript) {
    try {
      document.body.removeChild(noscript)
    } catch (e) {
      console.error(e)
    }
  }
}

const removeMeta = (meta: HTMLMetaElement) => {
  document.head.removeChild(meta)
}

const TagFunctions = {
  emptyTags,
  addScriptHeader,
  addScriptHeaderAsync,
  addNoscriptBody,
  addMetaHeader,
  removeTags,
  removeMeta,
}

export default TagFunctions
