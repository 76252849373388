import {
  Alert,
  AlertTitle,
  Box,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import {
  TCheckoutOrder,
  TCheckoutOrderDetails,
} from '../../../../core/types/CheckoutOrder'
import SendIcon from '@mui/icons-material/Send'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RepayIcon from '@mui/icons-material/PaymentsOutlined'
import React from 'react'
import OrderDetailOrder from './Tabs/OrderDetailOrder'
import OrderDetailCustomer from './Tabs/OrderDetailCustomer'
import OrderDetailValues from './Tabs/OrderDetailValues'
import OrderController from '../../../../core/controllers/OrderController'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import OrderDetailTracking from './Tabs/OrderDetailTracking'
import ContentDialog from '../../../../components/Dialogs/ContentDialog'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import ImageIlustrationPaymentes from '../../../../components/Images/ImageIlustrationPaymentes'
import ImageIlustrationRepay from '../../../../components/Images/ImageIlustrationRepay'
import CancelIcon from '@mui/icons-material/Cancel'
import OrderFunctions from '../../../../core/functions/OrderFunctions'
import { TabPanel, a11yProps } from '../../../../components/Tabs'
import OrderDetailPayments from './Tabs/OrderDetailPayments'

export interface ICheckoutOrderDetailsProp {
  checkoutOrder?: TCheckoutOrder
  open: boolean
  isOpened: boolean
  handleClose: () => void
  setOpen: (value: boolean) => void
  setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
  execute: () => void
  setCopied: (value: boolean) => void
}

const CheckoutOrderDetails = ({
  checkoutOrder,
  open,
  isOpened,
  handleClose,
  setOpen,
  setSending,
  setSuccess,
  setError,
  execute,
  setCopied,
}: ICheckoutOrderDetailsProp) => {
  const [value, setValue] = React.useState(0)

  const [openRepay, setOpenRepay] = React.useState(false)
  const [openNotRepay, setOpenNotRepay] = React.useState(false)
  const [openManualPayment, setOpenManualPayment] = React.useState(false)
  const [openSubscriptionPaymentCancel, setOpenSubscriptionPaymentCancel] =
    React.useState(false)

  const [data, setData] = React.useState<TCheckoutOrderDetails | undefined>(
    undefined
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMyClose = () => {
    handleMenuClose()
    handleClose()
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleRepay = () => {
    handleMenuClose()
    if (!checkoutOrder?.inAssurance) {
      setOpenNotRepay(true)
    } else {
      setOpenRepay(true)
    }
  }

  const handleManualPayment = () => {
    handleMenuClose()
    if (OrderFunctions.isPaid(checkoutOrder)) {
      manualPayment()
    } else {
      setOpenManualPayment(true)
    }
  }

  const handleSubscriptionPaymentCancel = () => {
    handleMenuClose()
    subscriptionPaymentCancel()
  }

  const repay = async (): Promise<boolean> => {
    setSuccess(false)
    setError('')
    try {
      const orderChargeId =
        checkoutOrder?.orderChargeId || data?.orderChargeId || ''

      var response = await OrderController.deleteCharge({ orderChargeId })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleClose()
        execute()
      }
    } finally {
      return true
    }
  }

  const manualPayment = async () => {
    setSuccess(false)
    setError('')
    try {
      var response = await OrderController.manualPayment({
        checkoutOrderId: checkoutOrder?.id || '',
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleClose()
        execute()
      }
    } finally {
      return true
    }
  }

  const subscriptionPaymentCancel = async () => {
    setSuccess(false)
    setError('')
    try {
      var response = await OrderController.subscriptionPaymentCancel({
        checkoutOrderId: checkoutOrder?.id || '',
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleClose()
        execute()
      }
    } finally {
      return true
    }
  }

  const getDetails = React.useCallback(
    async (id: string, productPriceId?: string) => {
      try {
        setSending(true)
        setError('')
        if (data) {
          setData(undefined)
        }

        const response = await OrderController.getCheckoutOrderDetails({
          id,
          productPriceId,
        })
        var responseError = response as IErrorResponse

        if (responseError.code) {
          setError(responseError.error)
        } else {
          const responseData = response as TCheckoutOrderDetails
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setSending(false)
      }
    },
    []
  )

  React.useEffect(() => {
    if (checkoutOrder && checkoutOrder.id) {
      setValue(0)
      getDetails(checkoutOrder.id, checkoutOrder.productPriceId)
    }
  }, [checkoutOrder])

  return (
    <SwipeableDrawerRightDialog
      hideButtons
      open={open}
      onClose={handleMyClose}
      setOpen={setOpen}
      backgroundColor="#fafafa"
      title={`Detalhes da ${OrderFunctions.isSubscription(checkoutOrder) ? 'Assinatura' : 'Venda'}`}
      subtitle="Visualize os detalhes da venda realizada"
    >
      <>
        <Stack direction="column" sx={{ width: '100%' }}>
          <Box>
            <Stack
              direction="row"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              alignItems="center"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
                sx={{ flexGrow: 1 }}
              >
                <Tab label="Venda" {...a11yProps(0)} />
                <Tab label="Cliente" {...a11yProps(1)} />
                <Tab label="Valores" {...a11yProps(2)} />
                <Tab label="Track" {...a11yProps(3)} />
                {OrderFunctions.isSubscription(checkoutOrder) && (
                  <Tab label="Pagamentos" {...a11yProps(4)} />
                )}
              </Tabs>
              {OrderFunctions.isPaid(checkoutOrder) && (
                <div>
                  <IconButton
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleRepay}>
                      <ListItemIcon>
                        <RepayIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Reembolsar venda</ListItemText>
                    </MenuItem>

                    <MenuItem onClick={handleManualPayment}>
                      <ListItemIcon>
                        <SendIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Reenviar eventos</ListItemText>
                    </MenuItem>

                    {OrderFunctions.isSubscriptionAndNotPaymentCancel(
                      checkoutOrder,
                      data
                    ) && (
                        <MenuItem onClick={handleSubscriptionPaymentCancel}>
                          <ListItemIcon>
                            <CancelIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>
                            Cancelar cobrança de assinatura
                          </ListItemText>
                        </MenuItem>
                      )}
                  </Menu>
                </div>
              )}
              {/*OrderFunctions.isPendings(checkoutOrder) && (
                <div>
                  <IconButton
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleManualPayment}>
                      <ListItemIcon>
                        <PayIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Aprovar pagamento</ListItemText>
                    </MenuItem>
                  </Menu>
                </div>
              )*/}
            </Stack>
            <TabPanel value={value} index={0}>
              <OrderDetailOrder
                checkoutOrder={checkoutOrder}
                details={data}
                isOpened={isOpened}
                setSending={setSending}
                setSuccess={setSuccess}
                setError={setError}
                execute={execute}
                getDetails={getDetails}
                setCopied={setCopied}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <OrderDetailCustomer
                checkoutOrder={checkoutOrder}
                details={data}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <OrderDetailValues checkoutOrder={checkoutOrder} details={data} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <OrderDetailTracking
                checkoutOrder={checkoutOrder}
                details={data}
                setSending={setSending}
                setSuccess={setSuccess}
                setError={setError}
              />
            </TabPanel>
            {OrderFunctions.isSubscription(checkoutOrder) && (
              <TabPanel value={value} index={4}>
                <OrderDetailPayments
                  checkoutOrder={checkoutOrder}
                  details={data}
                />
              </TabPanel>
            )}
          </Box>
        </Stack>

        <ContentDialog
          open={openRepay}
          onClose={() => setOpenRepay(false)}
          title="Como funciona o reembolso"
          labelPrositive="Reembolsar"
          onPositive={repay}
        >
          <Container>
            <ImageIlustrationRepay />
            <Typography>
              Após a solicitação do estorno, a solicitação é enviada a operadora
              do cartão do cliente, que é o responsável pela devolução do saldo
              no cartão dele.
            </Typography>
            <Typography>
              Assim, o cliente pode receber esse valor de 3 formas de acordo com
              a operadora/banco emissor:
            </Typography>
            <ul>
              <li>
                <Typography>
                  O banco estorna o valor total da transação na fatura seguinte
                  à solicitação do estorno; liberando o limite do cartão do
                  cliente e cobrando o restante das parcelas normalmente.
                </Typography>
              </li>
              <li>
                <Typography>
                  O banco cobra todas as parcelas e faz um estorno total apenas
                  na última fatura da cobrança
                </Typography>
              </li>
              <li>
                <Typography>
                  O banco faz a cobrança e o estorno da parcela na mesma fatura.
                  Em casos de estorno parcial, o banco pode dividir o valor do
                  estorno pelo restante das transações – nesse caso é cobrada
                  uma parcela original e devolvida uma parcela de estorno na
                  mesma fatura.
                </Typography>
              </li>
            </ul>
          </Container>
        </ContentDialog>

        <ContentDialog
          open={openNotRepay}
          onClose={() => setOpenNotRepay(false)}
          title="Operação inválida"
          hasCancel={false}
        >
          <Typography>
            Não é permitido o reembolso após o período de garantia.
          </Typography>
          <Alert
            variant="standard"
            severity="info"
            sx={{ width: '100%', mt: 3 }}
          >
            Só é permitido o reembolso enquanto o produto estiver no período de
            garantia
          </Alert>
        </ContentDialog>

        <ContentDialog
          open={openManualPayment}
          onClose={() => setOpenManualPayment(false)}
          title="Como funciona a aprovação do pagamento"
          labelPrositive="Aprovar pagamento"
          onPositive={manualPayment}
        >
          <Container>
            <ImageIlustrationPaymentes />
            <Typography>
              Ideal para pagamentos do tipo boleto em que o cliente está com o
              comprovante em mãos e não precisa esperar que a plataforma
              identifique o pagamento automaticamente.
            </Typography>
            <br />
            <Typography>
              Após a aprovação do pagamento, a venda é identificada como paga e
              será disparado os eventos conforme configurações.
            </Typography>
            <Alert severity="info" variant="standard" sx={{ my: 2 }}>
              Liberação imediata para o acesso a área de membros.
            </Alert>
            <Alert severity="warning" variant="standard" sx={{ my: 2 }}>
              <AlertTitle>Alerta</AlertTitle>
              Após esta ação, só será permitido realizar o reembolso como
              cancelamento da compra.
            </Alert>
          </Container>
        </ContentDialog>

        <ContentDialog
          open={openSubscriptionPaymentCancel}
          onClose={() => setOpenSubscriptionPaymentCancel(false)}
          title="Cancelar assinatura"
          labelPrositive="Aprovar cancelamento de assinatura"
          onPositive={subscriptionPaymentCancel}
        >
          <Typography>
            {' '}
            Ao cancelar a cobrança de assinatura, a assinatura expirará após a
            data de renovação, e o cliente não será cobrado no futuro.{' '}
          </Typography>
        </ContentDialog>
      </>
    </SwipeableDrawerRightDialog>
  )
}

export default CheckoutOrderDetails
