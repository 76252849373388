import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material'
import ContentDialog from '../../../../components/Dialogs/ContentDialog'
import { webhookExamples } from '../../../../core/data/webhookExamples'
import ReactJson from 'react-json-view'
import { webhookSendExampla } from '../../../../core/data/webhookSendExample'
import { useState } from 'react'
import Hosts from '../../../../core/functions/hosts'
import WebhookFunction from '../../../../core/functions/webhooks'
import { a11yProps, TabPanel } from '../../../../components/Tabs'
import { webhookSendAffiliatedExample } from '../../../../core/data/webhookSendAffiliatedExample'

interface IWebhookExamplesProp {
  open: boolean
  setOpen: (value: boolean) => void
  setCopied: (value: boolean) => void
}

const WebhookExamples = ({
  open,
  setOpen,
  setCopied,
}: IWebhookExamplesProp) => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = (event: React.SyntheticEvent, index: number) => {
    setTabIndex(index)
  }

  return (
    <ContentDialog
      open={open}
      title="Exemplo JSON de envio dos webhooks"
      maxWidth="md"
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Especificações da compra" {...a11yProps(0)} />
            <Tab label="Exemplo JSON de compra" {...a11yProps(1)} />
            <Tab label="Exemplo JSON de afiliação" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0} sx={{ p: 2 }}>
          <Stack direction="column" spacing={2}>
            <Alert severity="info">
              <AlertTitle>event_type</AlertTitle>
              Tipo do evento disparado para os webhooks
            </Alert>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Evento (event_type)</TableCell>
                    <TableCell>Status (order_status)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {WebhookFunction.webhookByPayments.map(
                    (webhookEvent, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2">
                            {webhookEvent.payment}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {webhookEvent.events
                            .map((we) => WebhookFunction.eventType(we))
                            .join(', ')}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Alert severity="info">
              <AlertTitle>JSON</AlertTitle>
              Identificação dos campos principais do JSON de envio do webhook
            </Alert>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Campo</TableCell>
                    <TableCell>Descrição</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {webhookExamples.map((row) => (
                    <TableRow
                      key={row.label}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell>{row.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </TabPanel>
        <TabPanel value={tabIndex} index={1} sx={{ p: 2 }}>
          <Stack
            direction="row"
            spacing={2}
            alignContent="end"
            alignItems="end"
            justifyContent="end"
            sx={{ pb: 2 }}
          >
            <Button
              variant="text"
              size="small"
              onClick={async () => {
                await Hosts.copyTextToClipboard(
                  JSON.stringify(webhookSendExampla)
                )
                setCopied(true)
              }}
            >
              Copiar JSON de exemplo
            </Button>
          </Stack>
          <ReactJson src={webhookSendExampla} />
        </TabPanel>
        <TabPanel value={tabIndex} index={2} sx={{ p: 2 }}>
          <Stack
            direction="row"
            spacing={2}
            alignContent="end"
            alignItems="end"
            justifyContent="end"
            sx={{ pb: 2 }}
          >
            <Button
              variant="text"
              size="small"
              onClick={async () => {
                await Hosts.copyTextToClipboard(
                  JSON.stringify(webhookSendAffiliatedExample)
                )
                setCopied(true)
              }}
            >
              Copiar JSON de exemplo
            </Button>
          </Stack>
          <ReactJson src={webhookSendAffiliatedExample} />
        </TabPanel>
      </Box>
    </ContentDialog>
  )
}

export default WebhookExamples
